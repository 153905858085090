import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Calendar, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse; 
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.bookinghistory;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item> 
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.bookeditems}</strong></span>}
      description={<div>Total Amount: <strong>&pound; {title.total_amount}</strong>  | Discount:  <strong>&pound; {title.discount_amount}</strong>  |  Paid Amount: <strong>&pound; {title.paid_amount}</strong>  { title.credits_pay && <strong> (Paid through credits - &pound; {title.credits_pay})</strong> } <br /> Payment Source: {title.payment_source} | Booking Date: {title.dateofbooking}</div>}
 /> 
 </List.Item> 
  );
  return (
    <List>  
    {listItems}  
    </List> 
  );
}


function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Booking History" key="1">
    <Classlist classlists={props.classlists} bookinghistory={props.bookinghistory} />
    </Panel></Collapse><br />
     </div>
  );
}


class CompBookinghistory extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
      <div className="site-card-wrapper">
      <Spin tip="Loading..."  spinning={this.props.loadclassflag} >
      <Courseclassesnew                
                classlists = {this.props.classlists}  
                bookinghistory = {this.props.bookinghistory}           
           />
</Spin>
      </div></div></div>
     
     
     
    );
}
}
export default CompBookinghistory;