import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Bookinghistory.css';
import "../../css/fonts.css";
import { Layout, Menu,Spin } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompBookinghistory from '../../components/Bookinghistory';
import { getbookinghistory,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Redirect } from "react-router-dom";
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE} from '../../constants';
import { Helmet } from 'react-helmet';

const {  Content,  Sider } = Layout;

const databreadcrumb = [
  {
    title: 'Dashboard',
    linkurl: ''
  },
  
];

function getCurrentDate(separator='',format=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if(format == 1){
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }else{
   
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  }
  
  }

class Bookinghistory extends Component {

  constructor(props){
    super(props); 


    
    var clstdate = getCurrentDate('-',1);
    var clenddate = getCurrentDate('-',1);
    this.state = {
      collapsed: false,
      loggedstatus:'logged',
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      filterparams:{
        clastdate: clstdate,
        clsenddate: clenddate,
        classstatus: 1,
      },
      classlists: [],
      loadclassflag: false,
      bookinghistory: [],
      cartid:'',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta',   
    };

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);    
  }  



  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  
  componentDidMount(){ 
    this.loadCurrentUser();  
   
  }

  getuserbookinghistory(){

    let udata  = this.state.loggeuserdata;
    let bsource = this.state.bookingsource;  

    this.setState({loadclassflag: true});     
    const data={
      email: udata.email, 
      bsource: bsource,     
    }     
    getbookinghistory(data).then(response => {
          if(response.status == 'success'){           
            this.setState({bookinghistory: response.bookinghistory});
          }
          this.setState({loadclassflag: false}); 
      })
}

  loadCurrentUser(){

    let locstatus = false;
    let bsource = this.state.bookingsource; 

    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata}); 
      
    }

    if (localStorage.getItem(TA_CART_ID) !== null) {
      this.setState({cartid: localStorage.getItem(TA_CART_ID)});
    }

    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);      
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }  
    

  }

  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;            
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true}); 
            this.getuserbookinghistory();             
          }            
      }).catch(error => {
        
      });
  
   }

  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA);  
    localStorage.removeItem(TA_CART_ID);    
    this.props.history.push('/');     
}


  render() { 
   
    let loggedflag = this.state.loggedstatus;
    if (loggedflag != 'logged') {
        return <Redirect to='/' />
    }

    return(


      
      (this.state.loadingthemconfig == true) 
      
      ?

        <Layout>   
        <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title></Helmet>       
      <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartid} />
      <Layout>
      <Sider breakpoint="lg"  collapsedWidth="0">
      <Leftmenu />
      </Sider>
      <Layout>    
        <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
        <PageBreadCrumb 
        databreadcrumb = {databreadcrumb}
        
        />
                
        <CompBookinghistory 
            classlists = {this.state.classlists}
            loadclassflag = {this.state.loadclassflag}
            bookinghistory = {this.state.bookinghistory}
        />

        </Content>
        <CommonFooter />
      </Layout>
    </Layout>
    </Layout>

    :
    <Spin tip="Loading..." />
  
    );
  }
}

export default Bookinghistory;
