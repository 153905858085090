import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Addnewtest.css';
import "../../css/fonts.css";
import { Layout, Menu, message } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompAddnewtest from '../../components/Addnewtest';
import { Redirect } from "react-router-dom";
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN} from '../../constants';
import {reviewgetcoments,fngetspecimendetails,reviewrprtupdate,reviewstatusupdate,getdefaultcomments,generatereport,sendrpttouser,barcodevalidation} from '../../helpers/Apicommon';
import { Helmet } from 'react-helmet';


const queryString = require('query-string');

const {  Content,  Sider } = Layout;

function getCurrentDate(separator='',format=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if(format == 1){
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }else{
   
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  }
  
  }

class Addnewtest extends Component {

  constructor(props){
    super(props); 
    
    var date = getCurrentDate('-',1);
    var rptdate = getCurrentDate('-');

    this.state = {
      collapsed: true,
      loggedstatus:'logged',
      loggeuserdata:{
        firstname: 'Admin',
        lastname:'',
        email:''
      },
      listbdata: [],
      showloading: true,
      date: date,
      current: 0,
      filterdate: rptdate,
      persondetails: false,
      persondata:{
        test_for:'',
        first_name:'',
        last_name:'',
        gender:'',
        dob:'',
        email:'',
        phone:'',
        address1:'',
        address2:'',
        city:'',
        country:'',
        postcode:'',
        dateofarrival:'',
        plf_ref:'',
        user_id:'',
        specimenid:'',
        report_status:'',
        review_status:'',
        specimen_barcode:'',
        submit_status:'',
        submitted_date:'',
        specimenid:'',
        report_comments:'',
        rptgeneratedflag:'',
        reportgenurl:'',
        reportgendatetime:'',
        reportsenddatetime:'',
        report_send_flag:'',
        vaccination_status:'',
        passport_no:'',
        flight_no:'',
        ethinicity:'',
        country_zone:'',
     },
     reportcomments:'',
     files:{
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
     },
     fileList: [ ],
     specimenList:[],
     documentList:[],
     spuserdata:[],
     specimenfiles:{
      previewSpecimenVisible: false,
      previewSpecimenImage: '',
      previewSpecimenTitle: '',
     },
     docfiles:{
      previewDocVisible: false,
      previewDocImage: '',
      previewDocTitle: '',
     },
     profileupdateflag: false,
     spintloadflag : true,
     profvieflag1 : '1',
     profvieflag2: '0',
     profvieflag3 : '0',
     profvieflag4 : '0',  
     reviewstautdrop: {
        reveiwstatus:'',
        reivewstcmtns:''
     },
     barcodeval:'',
     reviewspinloader: false,
     reveiwcomments:[],
     reptstatusdep: {
      rptstatus:'',
      rptcomments:''
     },
     reprtspingloader: false,
     senttoreportflag: false,
     validstaus:false,
    };

    this.toggle = this.toggle.bind(this);
    this.hanleupdatereviewstatus = this.hanleupdatereviewstatus.bind(this);
    this.handleinputonchange = this.handleinputonchange.bind(this);
    this.fnsubmitreviewstatus = this.fnsubmitreviewstatus.bind(this);
    this.fngetreviewcomments = this.fngetreviewcomments.bind(this);
    this.handleinputonchangereport = this.handleinputonchangereport.bind(this);
    this.hanleupdaterreportstatus = this.hanleupdaterreportstatus.bind(this);
    this.fnsubmitreportstatus = this.fnsubmitreportstatus.bind(this);   
    this.handleloaddefcomments = this.handleloaddefcomments.bind(this); 
  
    this.handlegeneratereport = this.handlegeneratereport.bind(this);
    this.fnsubmitgeneratereport = this.fnsubmitgeneratereport.bind(this);
    this.handlesendtouserchange = this.handlesendtouserchange.bind(this);
    this.handlesendreprot = this.handlesendreprot.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handlevalidatebarcode = this.handlevalidatebarcode.bind(this);
    this.handleinputbarcodechange =this.handleinputbarcodechange.bind(this);
    
  }  
  onchange = current => {
    let change=current;
    this.setState({ current:current });
    
    this.setState({ current });
  
  };

  handlesendtouserchange(e){   
    this.setState({senttoreportflag: e.target.checked});  
  }

  componentDidMount(){ 
    this.loadCurrentUser();
    const quvalues = queryString.parse(this.props.location.search);

    if(quvalues.id){
      this.getspecimendetails(quvalues.id);
    }
    
  }

  loadCurrentUser(){
    let locstatus = false;
    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata}); 
    }
    

  }

  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA);      
    this.props.history.push('/');     
}



  clearallerrormesssage(){
    document.getElementById('error_rvstatus').innerHTML = '';
    document.getElementById('error_barcode').innerHTML = '';
    document.getElementById('error_rvcomments').innerHTML = ''; 
    document.getElementById('success_barcode').innerHTML = '';
  }

  hanleupdatereviewstatus(){

    this.clearallerrormesssage();   
    let cdata = this.state.reviewstautdrop;
    let validate = this.state.validstaus;
    let validateform = 1; 
    if(cdata.reveiwstatus.trim()== ""){  
     document.getElementById('rvstatus').focus();                   
     document.getElementById('error_rvstatus').innerHTML='Please select the review status';
     validateform = 0;
    }  
  
    // if(cdata.barcodeval.trim()== ""){  
    //   document.getElementById('barcodeval').focus();                   
    //   document.getElementById('error_barcode').innerHTML='Please enter the barcode value';
    //   validateform = 0;
    //  }  
 
    if(cdata.reivewstcmtns.trim() == ""){  
      document.getElementById('txtreveiwcomments').focus();                   
      document.getElementById('error_rvcomments').innerHTML='Please enter the comments';
      validateform = 0;
     }  


    if(validateform == 1){     
      this.fnsubmitreviewstatus();
    }

  }

 fnsubmitreviewstatus(){
  
   let cdata = this.state.reviewstautdrop;
   let spdata = this.state.persondata;
   let loggeduse = this.state.loggeuserdata;
    var data ={
       userid: spdata.user_id,
       specimenid: spdata.specimenid,
       status: cdata.reveiwstatus,
       comments: cdata.reivewstcmtns,        
       added_roletype: "admin",
       added_by: loggeduse.email,
     }  

     this.setState({reviewspinloader: true});
     reviewstatusupdate(data)
      .then(response => {  
 
          this.setState({showloading: false});  
          if(response.status == "success") {              
            this.setState({reviewspinloader: false});

            let cdata = this.state.reviewstautdrop;
            cdata.reveiwstatus = '';
            cdata.reivewstcmtns='';        

            this.setState({reviewstautdrop:cdata});
            this.setState({validstaus:false});
            
          }else{
            this.setState({reviewspinloader: false});
         } 
         this.getspecimendetails(spdata.specimenid);
          
      }).catch(error => {
        this.setState({reviewspinloader: false}); 
      });


//persondata
 }


 handlevalidatebarcode(){
  this.clearallerrormesssage();   
   let cdata = this.state.persondata;
  let validateform = 1; 
  if(cdata.specimen_barcode.trim()== ""){  
    document.getElementById('barcodeval').focus();                   
    document.getElementById('error_barcode').innerHTML='Please enter the barcode value';
    validateform = 0;
   }  

   if(validateform == 1){     
    this.validatebarcode();
  }
 }

 validatebarcode(){
  let barcode = this.state.barcodeval;

  let spdata = this.state.persondata;

  const data ={
    userid: spdata.user_id,
    specimenid: spdata.specimenid, 
    barcodeval : spdata.specimen_barcode,
  }

  barcodevalidation(data)
  .then(response => {  
      if(response.status == "success") {              
        message.success('Saved successfully');
        // document.getElementById('barcodeval').focus();                   
        // document.getElementById('success_barcode').innerHTML='';
      }else{
        this.setState({validstaus: false});
        document.getElementById('barcodeval').focus();                   
        document.getElementById('error_barcode').innerHTML='Invalid-Barcode already used';
     } 
      
  });

 }
 

 fngetreviewcomments(){  

  let spdata = this.state.persondata;
   var data ={
      userid: spdata.user_id,
      specimenid: spdata.specimenid,     
    }  
   
    reviewgetcoments(data)
     .then(response => {          
         if(response.status == "success") {              
          this.setState({reveiwcomments: response.commentslist});             
         }
         
     }).catch(error => {
      
     });


//persondata
}


  handleinputonchange(event) {
    this.clearallerrormesssage();     
    let elid = event.target.id;
    let cdata = this.state.reviewstautdrop;    
    if(elid == "rvstatus"){  
      cdata.reveiwstatus =  event.target.value;
    }
    if(elid == "txtreveiwcomments"){  
      cdata.reivewstcmtns =  event.target.value;
    }       
    console.log(cdata);
    this.setState({reviewstautdrop: cdata});   
       
 }

 clearallerrormesssagerept(){
  document.getElementById('error_rptstatus').innerHTML = '';
  document.getElementById('error_rptcomments').innerHTML = ''; 
}

handleinputbarcodechange(event){

  this.clearallerrormesssage();     
    let elid = event.target.id;
    let barcode = this.state.persondata;

    if( elid == 'barcodeval'){
      barcode.specimen_barcode = event.target.value;
    }
    this.setState({barcodeval:barcode});
    console.log(this.state.barcodeval);
}

 
 handleinputonchangereport(event) {
  this.clearallerrormesssagerept();     
  let elid = event.target.id;
  let cdata = this.state.reptstatusdep;    
  if(elid == "rpt_status"){  
    cdata.rptstatus =  event.target.value;
    cdata.rptcomments = '';
  }
  if(elid == "rtp_comments"){  
    cdata.rptcomments =  event.target.value;
  }       
  this.setState({reptstatusdep: cdata});   
   
}

handleloaddefcomments(){
   this.fngetreportcommentsdefault();
}

fngetreportcommentsdefault(){
  
  let cdata = this.state.reptstatusdep;
   var postdata ={     
      rptstatus: cdata.rptstatus+"comment"    
    } 
    this.setState({reprtspingloader: true});       

    getdefaultcomments(postdata)
    .then(response => {  

        this.setState({showloading: false});  
        if(response.status == "success") {            
          cdata.rptcomments =  response.rptdata;
          this.setState({reptstatusdep: cdata});                    
          this.setState({reprtspingloader: false});          
        }else{
          this.setState({reprtspingloader: false});
       }        
        
    }).catch(error => {
      this.setState({reprtspingloader: false}); 
    });

}



hanleupdaterreportstatus(){

  this.clearallerrormesssagerept();   
  let cdata = this.state.reptstatusdep;
  let validateform = 1; 
  if(cdata.rptstatus.trim()== ""){  
   document.getElementById('rpt_status').focus();                   
   document.getElementById('error_rptstatus').innerHTML='Please select the report status';
   validateform = 0;
  }  

  if(cdata.rptcomments.trim() == ""){  
    document.getElementById('rtp_comments').focus();                   
    document.getElementById('error_rptcomments').innerHTML='Please enter the comments';
    validateform = 0;
   }  

  if(validateform == 1){     
    this.fnsubmitreportstatus();
  }

}


fnsubmitreportstatus(){
  
  let cdata = this.state.reptstatusdep;
  let spdata = this.state.persondata;
  let loggeduse = this.state.loggeuserdata;
   var postdata ={     
      specimenid: spdata.specimenid,
      report_status: cdata.rptstatus,
      report_comments: cdata.rptcomments, 
      report_st_updatedby: loggeduse.email,
    } 
    this.setState({reprtspingloader: true});       

    reviewrprtupdate(postdata)
    .then(response => {  

        this.setState({showloading: false});  
        if(response.status == "success") {              
          this.setState({reprtspingloader: false});
          
        }else{
          this.setState({reprtspingloader: false});
       } 
       this.getspecimendetails(spdata.specimenid);
        
    }).catch(error => {
      this.setState({reprtspingloader: false}); 
    });


  }

  handlegeneratereport(){    
    this.fnsubmitgeneratereport();
  }



  fnsubmitgeneratereport(){  
    
    let spdata = this.state.persondata;   
     var postdata ={     
        spefimenid: spdata.specimenid,       
      } 
      this.setState({reprtspingloader: true});       
  
      generatereport(postdata)
      .then(response => {  
  
          this.setState({showloading: false});  
          if(response.status == "success") {              
            this.setState({reprtspingloader: false});
            
          }else{
            this.setState({reprtspingloader: false});
         } 
         this.getspecimendetails(spdata.specimenid);
          
      }).catch(error => {
        this.setState({reprtspingloader: false}); 
      });
  
  
    }


    handlesendreprot(){    
      this.fnsubmitsendreporttouser();
    }


    fnsubmitsendreporttouser(){  
    
      let spdata = this.state.persondata;   
       var postdata ={     
          spefimenid: spdata.specimenid,       
        } 
        this.setState({reprtspingloader: true});       
    
        sendrpttouser(postdata)
        .then(response => {  
    
            this.setState({showloading: false});  
            if(response.status == "success") {              
              this.setState({reprtspingloader: false});              
            }else{
              this.setState({reprtspingloader: false});
           } 
           this.getspecimendetails(spdata.specimenid);
            
        }).catch(error => {
          this.setState({reprtspingloader: false}); 
        });
    
    
      }
  


  getspecimendetails(id){


    var data={specimenid:id}
   
    fngetspecimendetails(data)
    .then(response => { 
          if(response.status == "success"){    

            let specimendetails = response.specimendetails;
            let stataval = this.state.persondata;

            let rptstaus = this.state.reptstatusdep;

            stataval.test_for = specimendetails.user_type;
            stataval.first_name = specimendetails.first_name;
            stataval.last_name = specimendetails.last_name;
            stataval.gender = specimendetails.gender;
            stataval.dob = specimendetails.dob;
            stataval.email = specimendetails.user_email;
            stataval.phone = specimendetails.user_phone;
            stataval.address1 = specimendetails.user_address1;
            stataval.address2 = specimendetails.user_address2;
            stataval.city = specimendetails.user_city;
            stataval.country = specimendetails.user_country;
            stataval.postcode = specimendetails.user_postcode;
            stataval.dateofarrival = specimendetails.Date_of_arrival;
            stataval.plf_ref = specimendetails.PLF_ref;
            stataval.user_id = specimendetails.user_id;
            stataval.specimenid = specimendetails.ID;
            stataval.submitted_date = specimendetails.submitteddate;
            stataval.review_status = specimendetails.review_status;
            stataval.specimen_barcode=specimendetails.specimen_barcode;
            stataval.report_status = specimendetails.report_status;
            stataval.report_comments = specimendetails.report_comments;
            stataval.rptgeneratedflag = specimendetails.report_gen_flag;
            stataval.reportgenurl = specimendetails.reportdoc_path;
            stataval.reportgendatetime = specimendetails.report_gen_datetime;
            stataval.reportsenddatetime = specimendetails.report_send_datetime;
            stataval.report_send_flag = specimendetails.report_send_flag;
            stataval.vaccination_status = specimendetails.vacination_status;
            stataval.passport_no = specimendetails.passport_no;
            stataval.flight_no = specimendetails.flight_no;
            stataval.ethinicity = specimendetails.ethnicity;
            stataval.country_zone = specimendetails.country_zone;

            rptstaus.rptstatus = specimendetails.report_status;
            rptstaus.rptcomments = specimendetails.report_comments;
           
            this.setState({documentList:  response.profiledouments });
            this.setState({specimenList:  response.specimenprofiledocuemnts });
            this.setState({persondata: stataval });
            this.setState({spuserdata:  response.userdetails });  
            this.setState({reveiwcomments:  response.reportcomments }); 
            this.setState({reptstatusdep:  rptstaus });   
            

          }
          else{

          }

    });
  }
  

  toggle = () => {


    this.setState({
      collapsed: !this.state.collapsed,
    });
  };




  render() { 
   
    let loggedflag = this.state.loggedstatus;
    if (loggedflag != 'logged') {
        return <Redirect to='/' />
    }

    return(

    <Layout>
     <Helmet><title>The Hale Click - View Test Report</title></Helmet>         
    <CommonHeader userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} />
    <Layout>
    <Sider breakpoint="lg"  collapsedWidth="0">
    <Leftmenu />
    </Sider>
    <Layout>    
      <Content style={{ margin: '24px 16px 0' }}>
      <PageBreadCrumb />
       <CompAddnewtest     
        persondata={this.state.persondata}
        toggle = {this.toggle}
        documentList = {this.state.documentList}
        specimenList = {this.state.specimenList}
        spuserdata = {this.state.spuserdata}
        barcodeval={this.state.barcodeval}
        reviewstautdrop={this.state.reviewstautdrop}
        hanleupdatereviewstatus = {this.hanleupdatereviewstatus}
        handlevalidatebarcode = {this.handlevalidatebarcode}
        handleinputonchange = {this.handleinputonchange}
        reviewspinloader = {this.state.reviewspinloader}
        reveiwcomments = {this.state.reveiwcomments}
        reptstatusdep = {this.state.reptstatusdep}
        handleinputonchangereport = {this.handleinputonchangereport}
        hanleupdaterreportstatus = {this.hanleupdaterreportstatus}
        reprtspingloader = {this.state.reprtspingloader}
        handleloaddefcomments = {this.handleloaddefcomments}
        handlegeneratereport = {this.handlegeneratereport}
        senttoreportflag = {this.state.senttoreportflag}
        handlesendtouserchange = {this.handlesendtouserchange}
        handlesendreprot = {this.handlesendreprot}
        handleinputbarcodechange={this.handleinputbarcodechange}
       
      />        
      </Content>
     <CommonFooter />
    </Layout>
  </Layout>
  </Layout>
  
    );
  }
}

export default Addnewtest;
