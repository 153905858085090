import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Calendar, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.coursename} - {title.stdatetext}</strong></span>}
      description={<div>@ {title.locationtext}, Tutor(s): {title.tutortext} <br />Attended the Class: {title.attendedflag}</div>}
 />
 
 </List.Item>
  );
  return (
   
        (classdata.length > 0) ?
        <List>{listItems}</List> 
        :
        <div>No Records Found</div>   
   
  );
}


function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Class list" key="1">
    <Classlist classlists={props.classlists} />
    </Panel></Collapse><br />
     </div>
  );
}


class Compclasses extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
      <div className="site-card-wrapper">

      <div className="filter_top" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
        
            <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer_heading">
               <div className="manage_course_label">
               <span>Select Month</span>   
               </div>
               <div className="manage_course_info heading-align ">
               <span>  <MonthPicker  placeholder="Select month"  onChange={this.props.handleonchangesubstdate}  />
                  <Button type="primary" className="btm-search" style={{marginLeft: 10}} onClick={this.props.handlesearchattdata} >Search</Button>  
                <span className="erromessage" id="error_filtermonth"></span>
                      
               </span> 
         </div> 
            
               </div>   
           </div>
           </div>
           </div> 
           <Spin tip="Loading..."  spinning={this.props.loadclassflag}> 
      <Courseclassesnew                
                classlists = {this.props.classlists}  
                handleonchangesubstdate = {this.handleonchangesubstdate}           
           />
           </Spin>

      </div></div></div>
     
     
     
    );
}
}
export default Compclasses;