import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Alert, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.subspaymentplanhistory;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.disptext}</strong></span>}
      description={<div>Paid Amount: <strong>&pound;{title.paid_amount}</strong> | Payment Source: <strong>{title.payment_method}</strong> | Paid Date: <strong>{title.paiddate}</strong> </div>}
 /> 
 </List.Item> 
  );
  return (
    <List>{listItems}</List> 
  );
}


function Cancelsubscription(props) {

  if (!props) {
    return null;
  }
 
  return (
    <div>
  <div class="profile_info_left mt-10">
          
           <div className="save_button_last" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
               <Popconfirm
                  title="Are you sure cancel this subscription?"  
                  onConfirm={props.handlecancelsubsprocess}                            
                  okText="Yes"
                  cancelText="No"
                >
                <Button type="primary" className="btm-review">Cancel Subscription</Button>
              </Popconfirm>


          </div>

  </div></div>
  );
}


function Renewsubscription(props) {

  if (!props) {
    return null;
  }
  
  let subdetails = props.subsplandeatils;

  return (
    <div>
  <div class="profile_info_left mt-10">
  <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Subscription Start Date</span>  
               </div>
               <div className="manage_course_info mwidth-auto">           
               <DatePicker  id="stdate" defaultValue={moment(subdetails.posnextrenewaldate, dateFormat)} onChange={props.handleonchangesubstdate}  />
               <span className="erromessage" id="error_stdate"></span>
               </div>   
           </div>
           </div>
           </div> 
    
          
           <div className="save_button_last" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
           <Popconfirm
                  title="Are you sure renew this subscription?"  
                  onConfirm={props.handlerenewalprocess}                        
                  okText="Yes"
                  cancelText="No"
                >
               <Button type="primary" className="btm-review">Renew Subscription</Button>
               </Popconfirm>
          </div>

  </div></div>
  );
}



function Subscriptionlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.subscriptionlists;  
  //id="usrcountry" selval={userdata.country} handleinputchangenewuser={props.handleinputchangenewuser}
  const listItems = classdata.map((title,index) =>        
  <option value={title.item_id}>{title.item_name} - &pound;{ + title.item_price}</option>       
  );
  return (
    <select id={props.id} onChange={props.handleplanchange}>
      <option value="">--Select--</option>
      {listItems}
    </select>
  );
}

function Upgradesubscription(props) {

  if (!props) {
    return null;
  }
  
  let subdetails = props.subsplandeatils;

  return (
    <div>
  <div class="row">
  <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Subscription Start Date</span>  
               </div>
               <div className="manage_course_info mwidth-auto">           
               <DatePicker  id="stupdate" defaultValue={moment(props.upgradedefatuldate, dateFormat)}  onChange={props.handlechangeupgraddate}  />
               <span className="erromessage" id="error_stupdate"></span>
               </div>   
           </div>
           </div>
           </div> 
  <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Subscription</span>  
               </div>
               <div className="manage_course_info mwidth-auto">           
               <Subscriptionlist  id="stdate" subscriptionlists={props.subscriptionlists} handleplanchange={props.handleplanchange} />
               <span className="erromessage" id="error_stdate"></span>
               </div>   
           </div>
           </div>
           </div> 
           <div className="row"><div id="showupgrademessage"></div></div>
          
           <div className="save_button_last" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
           <Popconfirm
                  title="Are you sure to upgrade this subscription?"  
                  onConfirm={props.handleupgradedprocess}                        
                  okText="Yes"
                  cancelText="No"
                >
               <Button type="primary" className="btm-review">Upgrade</Button>
               </Popconfirm>
          </div>

  </div></div>
  );
}


function  Cancelinfotext (props) {

  if (!props) {
    return null;
  }

  let subdetails = props.subsplandeatils;
  return (
   <div><b>{subdetails.cancel_popupinfo}</b>
         </div>
  );
}

function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Information" key="1">
    <div dangerouslySetInnerHTML={{ __html: props.subsplandeatils.cancel_popupinfo }} />  

      <div className="save_button_last btn-center" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
      <Button type="primary" className="btm-canreview" onClick={props.handlecancelsubsprocess}>Cancel Subscription</Button>
      <Button type="primary" className="btm-review" onClick={props.handlesubscontineemail}>Continue Subscription</Button>     
          </div> 
    </Panel></Collapse><br />
     </div>
  );
}


class Compcancelsubscription extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
     <div className="site-card-wrapper">

     <Spin tip="Loading..."  spinning={this.props.loadclassflag}> 

      <div className="filter_top" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
          <div className="top_header_filer_heading">
    
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer_heading">
           <List>  
    <List.Item>
  <List.Item.Meta           
      title={<span><strong>1. {this.props.subsplandeatils.subscription_title}</strong></span>}
      description={<div>Price: <strong>&pound;{this.props.subsplandeatils.subscription_amount} </strong> <br /> Start Date : <strong>{this.props.subsplandeatils.subscription_startdate} </strong> | Next Renewal Date: <strong> {this.props.subsplandeatils.next_renewal_date} </strong> <br />Status: <strong>{this.props.subsplandeatils.subscription_status} </strong> </div>}
 /> 
 </List.Item>    
    </List> 
           </div>
           </div>
           </div> 
          </div>       
      </div>  


      <Courseclassesnew                
                classlists = {this.props.classlists}  
                subspaymentplanhistory = {this.props.subspaymentplanhistory}  
                handleonchangesubstdate = {this.props.handleonchangesubstdate}
                handlerenewalprocess = {this.props.handlerenewalprocess} 
                subsplandeatils = {this.props.subsplandeatils}  
                handlecancelsubsprocess = {this.props.handlecancelsubsprocess}  
                subscriptionlists = {this.props.subscriptionlists} 
                handleplanchange = {this.props.handleplanchange}                
                handlechangeupgraddate = {this.props.handlechangeupgraddate}
                upgradeplandata = {this.props.upgradeplandata}
                handleupgradedprocess = {this.props.handleupgradedprocess}
                upgradedefatuldate = {this.props.upgradedefatuldate}
                upgpplanmessage = {this.props.upgpplanmessage}
                handlesubscontineemail = {this.props.handlesubscontineemail}
           />
           </Spin>

      </div></div></div>
     
     
     
    );
}
}
export default Compcancelsubscription;