import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Viewsubscription.css';
import "../../css/fonts.css";
import { Layout, Menu,Spin } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import Compviewsubscription from '../../components/Viewsubscription';
import { addtocartcheckout,getsubscriptiondata,cancelsubscription,getsubslists,calculateupgradefee,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Redirect } from "react-router-dom";
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE} from '../../constants';
import { Helmet } from 'react-helmet';
import moment from 'moment';

const {  Content,  Sider } = Layout;
const queryString = require('query-string');
const databreadcrumb = [
  {
    title: 'Dashboard',
    linkurl: ''
  },
  
];

function getCurrentDate(separator='',format=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if(format == 1){
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
  }else{
   
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  }
  
  }

class Viewsubscription extends Component {

  constructor(props){
    super(props); 


    
    var clstdate = getCurrentDate('-',1);
    var clenddate = getCurrentDate('-',1);
    this.state = {
      collapsed: false,
      loggedstatus:'logged',
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      filterparams:{
        clastdate: clstdate,
        clsenddate: clenddate,
        classstatus: 1,
      },
      classlists: [],
      loadclassflag: false,
      subsid: 0,
      subsplandeatils: [],
      subspaymentplanhistory: [],
      renewaldata:{       
        item_id:'',
        item_name:'',
        item_price:'',
        item_qty:'',
        item_category:'',
        item_stdate: clstdate,
      },
      subscriptionpartnerid: 0,
      cartid:'',   
      subscriptionlists: [],  
      upgradeplandata:{       
        item_id:'',
        item_name:'',
        item_price:'',
        item_qty:'',
        item_category:'',
        item_stdate: getCurrentDate('-',1),  
        old_plan_id:'',      
      }, 
      upgradedefatuldate: getCurrentDate('-'),
      upplandetails:[],
      upgpplanmessage: '',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta',   

    };

    this.handleLogout = this.handleLogout.bind(this);  
    this.handleonchangesubstdate = this.handleonchangesubstdate.bind(this); 
    this.handlerenewalprocess = this.handlerenewalprocess.bind(this); 
    this.handlecancelsubsprocess = this.handlecancelsubsprocess.bind(this);
    this.handleplanchange = this.handleplanchange.bind(this);    
    this.handlechangeupgraddate = this.handlechangeupgraddate.bind(this);
    this.handleupgradedprocess = this.handleupgradedprocess.bind(this);
  } 

  handleupgradedprocess(){

    document.getElementById('error_stupdate').innerHTML= '';

    let subsplandata  = this.state.subsplandeatils;
    let udata  = this.state.upgradeplandata;

    let subendate  = subsplandata.subscription_endate;
    let alloweddate = new Date(subendate);

    let seldate = udata.item_stdate;
    let ttmseldate =  new Date(seldate);

    let timstp = alloweddate.getTime();
    let selecteddate = ttmseldate.getTime();    
    
    if(selecteddate > timstp){
          document.getElementById('error_stupdate').innerHTML='Start date should be within the end date of the current subscription';     
    }else{
      let upplandata  = this.state.upgradeplandata;
      console.log(upplandata);

      this.handlecheckoutupgrade();
        
      /*
        let upplandata  = this.state.upgradeplandata;
        upplandata.item_name = subsplan.subscription_title + " - Renewal";
        upplandata.item_id = subsplan.subscriptionid;
        upplandata.item_price = subsplan.renewalprirce;
        upplandata.item_qty = 1;
        upplandata.item_category = 'subscriptionrenewal';
        upplandata.item_stdate = subsplan.posnextrenewaldate; 
    */


    }

  }



  handlecheckoutupgrade(){
   
    let rtdata  = this.state.upgradeplandata;
    let pid  = this.state.subscriptionpartnerid;
    let udatea  = this.state.loggeuserdata;
    //let rtdata  = this.state.renewaldata;

   const data = {
      cart_id: 0, 
      cart_item: rtdata,
      user_id: udatea.userid, 
      partner_id: pid,         
    }   
          
    addtocartcheckout(data).then(response => {
      if(response.status == 'success'){  
          let checouurl = response.basket_url;
          window.location.href = checouurl;            
      }        
    });     

}


  handlechangeupgraddate(date, dateString){
    document.getElementById('error_stupdate').innerHTML= '';    
    let udata  = this.state.upgradeplandata;
    udata.item_stdate = dateString;
    this.setState({upgradeplandata: udata});     
  }
    
  
  handleplanchange(event){   
    
    let seleplanid  =  event.target.value;
    this.calculateupgplan(seleplanid);
 }

 calculateupgplan(uplanid){
    let subsplandata  = this.state.subsplandeatils;
    let upplan  = this.state.upgradeplandata;
    let udatea  = this.state.loggeuserdata;

    const data = {
      nsubsplanid: uplanid, 
      csubsplanid: subsplandata.subscriptionid,
      userid: udatea.userid,  
      upgradstdate: upplan.item_stdate,             
    } 

    calculateupgradefee(data).then(response => {
      if(response.status == 'success'){  
        let rsdat  = response.message;
        let upsplan = response.upplandetails;
        document.getElementById('showupgrademessage').innerHTML= rsdat; 
        let upplandata  = this.state.upgradeplandata;
        let subsplandata2  = this.state.subsplandeatils;
        upplandata.item_name = upsplan.subscription_title;
        upplandata.item_id = upsplan.id;
        upplandata.item_price = response.upgradefee;
        upplandata.item_qty = 1;
        upplandata.item_category = 'upgradersubscription';
        upplandata.old_plan_id = subsplandata2.subscriptionid;
        this.setState({upgradeplandata: upplandata});  

      }        
    }); 


    
 }


  handlecancelsubsprocess(){

    let rtdata  = this.state.renewaldata;
    let udatea  = this.state.loggeuserdata;

    const data = {
      subs_id: rtdata.item_id, 
      user_id: udatea.userid,
      admin_userid: udatea.userid,              
    } 
    cancelsubscription(data).then(response => {
      if(response.status == 'success'){  
          window.location.reload();           
      }        
    });    

  }



  handlerenewalprocess(){
   
      let rtdata  = this.state.renewaldata;
      let pid  = this.state.subscriptionpartnerid;
      let udatea  = this.state.loggeuserdata;
      //let rtdata  = this.state.renewaldata;

     let bsource = this.state.bookingsource; 

     const data = {
        cart_id: 0, 
        cart_item: rtdata,
        user_id: udatea.userid, 
        partner_id: pid,  
        cart_source: bsource,        
      }   
            
      addtocartcheckout(data).then(response => {
        if(response.status == 'success'){  
            let checouurl = response.basket_url;
            window.location.href = checouurl;            
        }        
      });     

  }
  

  handleonchangesubstdate(date, dateString){
       let udata  = this.state.renewaldata;
       udata.item_stdate = dateString;
       this.setState({renewaldata: udata});  

  }
  
  componentDidMount(){ 
    
    
    const quvalues = queryString.parse(this.props.location.search);
    if(quvalues.id ){     
      let subid = quvalues.id;       
      this.setState({subsid: subid}); 
      this.loadCurrentUser(subid);  
    } 
   
    
    //this.getclasslists(); 
  }

  getclasslists(subid,userid){

    this.setState({loadclassflag: true}); 

    let searchprops = this.state.filterparams;   
    const data={
      subscription_id: subid, 
      student_id: userid,        
    }     
    getsubscriptiondata(data).then(response => {
          if(response.status == 'success'){           
            this.setState({subsplandeatils: response.subs_plan});
            this.setState({subspaymentplanhistory: response.student_plan_history});

            let subsplan =  response.subs_plan;
            let sdata  = this.state.renewaldata;
            sdata.item_name = subsplan.subscription_title + " - Renewal";
            sdata.item_id = subsplan.subscriptionid;
            sdata.item_price = subsplan.renewalprirce;
            sdata.item_qty = 1;
            sdata.item_category = 'subscriptionrenewal';
            sdata.item_stdate = subsplan.posnextrenewaldate;          

            this.setState({renewaldata: sdata});
            this.setState({subscriptionpartnerid: subsplan.subspartnerid});
            

            this.getsubscriptionlists(subsplan.subspartnerid);

          }
          this.setState({loadclassflag: false}); 
      })
}



getsubscriptionlists(spartnerid){

 
  let searchprops = this.state.filterparams;   
  const data={
    subcripiton_status: '',
    partner_id: spartnerid , 
    sub_ctype: 'class',     
  }     
  getsubslists(data).then(response => {
        if(response.status == 'success'){           
          this.setState({subscriptionlists: response.subscription_plans});
        }        
    })
}

  loadCurrentUser(subid){
    
    let locstatus = false;
    let bsource = this.state.bookingsource; 

    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata});     
      this.getclasslists(subid,udata.userid);        
    }

    if (localStorage.getItem(TA_CART_ID) !== null) {
      this.setState({cartid: localStorage.getItem(TA_CART_ID)});
    }

    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);      
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }    
    

  }


  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true});                              
          }            
      }).catch(error => {
        
      });
  
   }

  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA);  
    localStorage.removeItem(TA_CART_ID);     
    this.props.history.push('/');     
}


  render() { 
   
    let loggedflag = this.state.loggedstatus;
    if (loggedflag != 'logged') {
        return <Redirect to='/' />
    }

    return(

      (this.state.loadingthemconfig == true) 
      ?
    
    <Layout>   
    <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title></Helmet>       
   <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartid} />
   <Layout>
   <Sider breakpoint="lg"  collapsedWidth="0">
   <Leftmenu />
   </Sider>
   <Layout>    
     <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
     <PageBreadCrumb 
     databreadcrumb = {databreadcrumb}
     
     />
            
    <Compviewsubscription 
        classlists = {this.state.classlists}
        loadclassflag = {this.state.loadclassflag}
        subsplandeatils = {this.state.subsplandeatils}
        subspaymentplanhistory = {this.state.subspaymentplanhistory}
        handleonchangesubstdate = {this.handleonchangesubstdate}
        handlerenewalprocess = {this.handlerenewalprocess}
        handlecancelsubsprocess = {this.handlecancelsubsprocess}
        subscriptionlists = {this.state.subscriptionlists}
        handleplanchange = {this.handleplanchange}
        disabledEndDate = {this.disabledEndDate} 
        handlechangeupgraddate = {this.handlechangeupgraddate}
        upgradeplandata = {this.state.upgradeplandata}
        handleupgradedprocess = {this.handleupgradedprocess}
        upgradedefatuldate = {this.state.upgradedefatuldate}
        upgpplanmessage = {this.state.upgpplanmessage}

    />

     </Content>
    <CommonFooter />
   </Layout>
 </Layout>
 </Layout>

:
<Spin tip="Loading..." />
  
    );
  }
}

export default Viewsubscription;
