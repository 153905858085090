const config = {
    basketcount: '',   
    sitemode: 'uat',
    devurl:{
        loginurl: 'http://localhost/tangoapi/public/api/studentlogin',
        register: 'http://localhost/tangoapi/public/api/adminregister',   
        addspecimenprofile:'http://localhost/tangoapi/public/api/addspecimenprofile',
        getspecimendetails:'http://localhost/tangoapi/public/api/getspecimendetails',
        getspecimenlistadmin:"http://localhost/tangoapi/public/api/getspecimenlistadmin",  
        getadminlist:"http://localhost/tangoapi/public/api/getadminlist",
        deleteuser:"http://localhost/tangoapi/public/api/deleteuser",
        resetpassword:"http://localhost/tangoapi/public/api/resetpassword",
        reviewstatusurl: "http://localhost/tangoapi/public/api/addreviewstatus",
        reviewcommentsgeturl: "http://localhost/tangoapi/public/api/getreviewcomments",
        rptstatusupdate: "http://localhost/tangoapi/public/api/addreportstatus",
        getallusers: "http://localhost/tangoapi/public/api/getallusers",
        getdefaultcomments: "http://localhost/tangoapi/public/api/getrptcomment",
        getuserslist: "http://localhost/tangoapi/public/api/userslists",
        generatereport: "http://localhost/tangoapi/public/api/genreport",
        getreportlist: "http://localhost/tangoapi/public/api/getspecimenreportlist",
        sendrpttouser: "http://localhost/tangoapi/public/api/sendrptotuser",
        barcodevalidate: "http://localhost/tangoapi/public/api/validatebarcode",
        addstudent: "http://localhost/tangoapi/public/api/addstudent", 
        getuserdetailsbyid: "http://localhost/tangoapi/public/api/getstudentdetailsbyid",  
        updatestudent: "http://localhost/tangoapi/public/api/updatestudent", 
        addupdatesubscription: "http://localhost/tangoapi/public/api/managesubscription", 
        subscriptionlists: "http://localhost/tangoapi/public/api/getSubscriptionPlans",
        getsubsdetailsbyid: "http://localhost/tangoapi/public/api/getsubsdetailsbyid",          
        addupdatecourse: "http://localhost/tangoapi/public/api/createupdatecourse", 
        getsubcriberslist: "http://localhost/tangoapi/public/api/getsubcriberslist", 
        searchstudents: "http://localhost/tangoapi/public/api/searchstudentbyfilter", 
        courselists: "http://localhost/tangoapi/public/api/getcoursesbyfliter",
        courseparsmas: "http://localhost/tangoapi/public/api/courseparams",
        courseclasses: "http://localhost/tangoapi/public/api/getcourseclassebyid",
        getstudentsbyclass: "http://localhost/tangoapi/public/api/getassignedstudentsbyclass",
        assignstudent: "http://localhost/tangoapi/public/api/addsubscribers",
        getcoursedetailsbyid: "http://localhost/tangoapi/public/api/getcoursedetailsbyid",
        assigncoursestudent: "http://localhost/tangoapi/public/api/assignstutoclass",
        assignattendance: "http://localhost/tangoapi/public/api/assignattendance",
        searchclassesbyfilter: "http://localhost/tangoapi/public/api/searchclassebyfilter",
        assigncourseclassstudent: "http://localhost/tangoapi/public/api/assignstutocourse",
        getassignedstudentsbycourse: "http://localhost/tangoapi/public/api/getassignedstudentsbycourse",
        getattendancereport: "http://localhost/tangoapi/public/api/attendancereportbyfilter",
        userassignattendance: "http://localhost/tangoapi/public/api/userassignattendance",
        getuserbookedclasses: "http://localhost/tangoapi/public/api/getuserbookedclasses",
        getusergroups: "http://localhost/tangoapi/public/api/getusergroups",
        getuserbygroup: "http://localhost/tangoapi/public/api/getuserbygroup",
        addnewgroup: "http://localhost/tangoapi/public/api/addnewgroup",
        userassigntouser: "http://localhost/tangoapi/public/api/assignusertogroup",
        delusergroup: "http://localhost/tangoapi/public/api/deleteusergroup",
        clonecourse: "http://localhost/tangoapi/public/api/clonecourse",
        feecalcclass: "http://localhost/tangoapi/public/api/revenueSummary",
        calculaterevenue: "http://localhost/tangoapi/public/api/classRevenue",
        reportdownloadurl: "http://localhost/tangoapi/public/api/downloadsummaryreport?course_date=",
        partnersummary: "http://localhost/tangoapi/public/api/partnerSummary",
        classsummary: "http://localhost/tangoapi/public/api/classSummary",
        tutorsummary: "http://localhost/tangoapi/public/api/tutorSummary",
        studentsummary: "http://localhost/tangoapi/public/api/studentSummary",
        partsummaryreport: "http://localhost/tangoapi/public/api/downloadpartersummary?course_date=",
        classummaryreport: "http://localhost/tangoapi/public/api/downloadclasssummary?course_date=",
        tutorsummaryreport: "http://localhost/tangoapi/public/api/downloadtutorsummary?course_date=",
        studentsummaryreport: "http://localhost/tangoapi/public/api/downloadstudentsummary?course_date=",       
        renewsubscription: "http://localhost/tangoapi/public/api/renewSubscriptions",
        cancelsubscription: "http://localhost/tangoapi/public/api/cancelSubscriptions",
        getcart: "http://localhost/tangoapi/public/api/getCart",
        getpaymentintent: "http://localhost/tangoapi/public/api/createPaymentIntent",
        bookingconfirmation: "http://localhost/tangoapi/public/api/bookingconfirmation",
        getsavedcards: "http://localhost/tangoapi/public/api/getpaymentmethods",
        getbookinghistory: "http://localhost/tangoapi/public/api/getbookinghistory",
        getsubscriptionlist: "http://localhost/tangoapi/public/api/getmysubscription",
        subsstudentdetails: "http://localhost/tangoapi/public/api/getsubsdetails",
        addtocart: "http://localhost/tangoapi/public/api/addtoCart",
        emailvalidate: "http://localhost/tangoapi/public/api/getemailvalidate",
        removecart: "http://localhost/tangoapi/public/api/removecard",
        getcountrylist: "http://localhost/tangoapi/public/api/getcountrylists",
        attendancereportbyMonth: "http://localhost/tangoapi/public/api/attendancereportbyMonth",
        classreportbyDay: "http://localhost/tangoapi/public/api/classreportbyDay",
        updatecartitem: "http://localhost/tangoapi/public/api/updatecartitem", 
        paymentconfirmurl: "http://localhost:3000/user/bookingconfirm?cartid=",
        upqtyitemurl: "http://localhost/tangoapi/public/api/upitemquantity",
        authwplogin: "http://localhost/laravel/tangoamistosov2/tangowebv3/wp-json/tatimetable/validateuser",  
        udetailsbyemail: "http://localhost/tangoapi/public/api/userautoauthdetails",
        caclupgradefee: "http://localhost/tangoapi/public/api/subsupgradefee",
        subcontinueemail: "http://localhost/tangoapi/public/api/subcontinueemail",
        getsmartconfig: "http://localhost/tangoapi/public/api/getsmartconfigdetails",
        tanamsstpkey: "pk_test_x2Hh3hUl3YIMB8pISx8p8Di000S6RZrb4p",
        reqrestpassword: "http://localhost/tangoapi/public/api/usrestpassword",
        otpresetpwd: "http://localhost/tangoapi/public/api/otprestpassword",
        getcreditsdata: "http://localhost/tangoapi/public/api/getcreditsdata",
        validate_token: "http://localhost/tangoapi/public/api/validate-token",
    },
    uaturl:{
        loginurl: 'https://dev.samrantech.com/tangoapi/public/api/studentlogin',
        register: 'https://dev.samrantech.com/tangoapi/public/api/adminregister',   
        addspecimenprofile:'https://dev.samrantech.com/tangoapi/public/api/addspecimenprofile',
        getspecimendetails:'https://dev.samrantech.com/tangoapi/public/api/getspecimendetails',
        getspecimenlistadmin:"https://dev.samrantech.com/tangoapi/public/api/getspecimenlistadmin",  
        getadminlist:"https://dev.samrantech.com/tangoapi/public/api/getadminlist",
        deleteuser:"https://dev.samrantech.com/tangoapi/public/api/deleteuser",
        resetpassword:"https://dev.samrantech.com/tangoapi/public/api/resetpassword",
        reviewstatusurl: "https://dev.samrantech.com/tangoapi/public/api/addreviewstatus",
        reviewcommentsgeturl: "https://dev.samrantech.com/tangoapi/public/api/getreviewcomments",
        rptstatusupdate: "https://dev.samrantech.com/tangoapi/public/api/addreportstatus",
        getallusers: "https://dev.samrantech.com/tangoapi/public/api/getallusers",
        getdefaultcomments: "https://dev.samrantech.com/tangoapi/public/api/getrptcomment",
        getuserslist: "https://dev.samrantech.com/tangoapi/public/api/userslists",
        generatereport: "https://dev.samrantech.com/tangoapi/public/api/genreport",
        getreportlist: "https://dev.samrantech.com/tangoapi/public/api/getspecimenreportlist",
        sendrpttouser: "https://dev.samrantech.com/tangoapi/public/api/sendrptotuser",
        addstudent: "https://dev.samrantech.com/tangoapi/public/api/addstudent",
        getuserdetailsbyid: "https://dev.samrantech.com/tangoapi/public/api/getstudentdetailsbyid",
        updatestudent: "https://dev.samrantech.com/tangoapi/public/api/updatestudent",   
        addupdatesubscription: "https://dev.samrantech.com/tangoapi/public/api/managesubscription", 
        subscriptionlists: "https://dev.samrantech.com/tangoapi/public/api/getSubscriptionPlans",
        getsubsdetailsbyid: "https://dev.samrantech.com/tangoapi/public/api/getsubsdetailsbyid",   
        addupdatecourse: "https://dev.samrantech.com/tangoapi/public/api/createupdatecourse", 
        getsubcriberslist: "https://dev.samrantech.com/tangoapi/public/api/getsubcriberslist", 
        searchstudents: "https://dev.samrantech.com/tangoapi/public/api/searchstudentbyfilter", 
        courselists: "https://dev.samrantech.com/tangoapi/public/api/getcoursesbyfliter",
        courseparsmas: "https://dev.samrantech.com/tangoapi/public/api/courseparams",
        courseclasses: "https://dev.samrantech.com/tangoapi/public/api/getcourseclassebyid",
        getstudentsbyclass: "https://dev.samrantech.com/tangoapi/public/api/getassignedstudentsbyclass",
        assignstudent: "https://dev.samrantech.com/tangoapi/public/api/addsubscribers",
        getcoursedetailsbyid: "https://dev.samrantech.com/tangoapi/public/api/getcoursedetailsbyid",
        assigncoursestudent: "https://dev.samrantech.com/tangoapi/public/api/assignstutoclass",
        assignattendance: "https://dev.samrantech.com/tangoapi/public/api/assignattendance",
        searchclassesbyfilter: "https://dev.samrantech.com/tangoapi/public/api/searchclassebyfilter",
        assigncourseclassstudent: "https://dev.samrantech.com/tangoapi/public/api/assignstutocourse",
        getassignedstudentsbycourse: "https://dev.samrantech.com/tangoapi/public/api/getassignedstudentsbycourse",
        getattendancereport: "https://dev.samrantech.com/tangoapi/public/api/attendancereportbyfilter",
        userassignattendance: "https://dev.samrantech.com/tangoapi/public/api/userassignattendance",
        getuserbookedclasses: "https://dev.samrantech.com/tangoapi/public/api/getuserbookedclasses",
        getusergroups: "https://dev.samrantech.com/tangoapi/public/api/getusergroups",
        getuserbygroup: "https://dev.samrantech.com/tangoapi/public/api/getuserbygroup",
        addnewgroup: "https://dev.samrantech.com/tangoapi/public/api/addnewgroup",
        userassigntouser: "https://dev.samrantech.com/tangoapi/public/api/assignusertogroup",
        delusergroup: "https://dev.samrantech.com/tangoapi/public/api/deleteusergroup",
        clonecourse: "https://dev.samrantech.com/tangoapi/public/api/clonecourse",  
        feecalcclass: "https://dev.samrantech.com/tangoapi/public/api/revenueSummary",
        calculaterevenue: "https://dev.samrantech.com/tangoapi/public/api/classRevenue", 
        reportdownloadurl: "https://dev.samrantech.com/tangoapi/public/api/downloadsummaryreport?course_date=", 
        partnersummary: "https://dev.samrantech.com/tangoapi/public/api/partnerSummary",  
        classsummary: "https://dev.samrantech.com/tangoapi/public/api/classSummary",  
        tutorsummary: "https://dev.samrantech.com/tangoapi/public/api/tutorSummary",
        studentsummary: "https://dev.samrantech.com/tangoapi/public/api/studentSummary",
        partsummaryreport: "https://dev.samrantech.com/tangoapi/public/api/downloadpartersummary?course_date=",
        classummaryreport: "https://dev.samrantech.com/tangoapi/public/api/downloadclasssummary?course_date=",
        tutorsummaryreport: "https://dev.samrantech.com/tangoapi/public/api/downloadtutorsummary?course_date=",
        studentsummaryreport: "https://dev.samrantech.com/tangoapi/public/api/downloadstudentsummary?course_date=",       
        renewsubscription: "https://dev.samrantech.com/tangoapi/public/api/renewSubscriptions", 
        cancelsubscription: "https://dev.samrantech.com/tangoapi/public/api/cancelSubscriptions",  
        getcart: "https://dev.samrantech.com/tangoapi/public/api/getCart", 
        getpaymentintent: "https://dev.samrantech.com/tangoapi/public/api/createPaymentIntent", 
        bookingconfirmation: "https://dev.samrantech.com/tangoapi/public/api/bookingconfirmation",
        getsavedcards: "https://dev.samrantech.com/tangoapi/public/api/getpaymentmethods", 
        getbookinghistory: "https://dev.samrantech.com/tangoapi/public/api/getbookinghistory", 
        getsubscriptionlist: "https://dev.samrantech.com/tangoapi/public/api/getmysubscription", 
        subsstudentdetails: "https://dev.samrantech.com/tangoapi/public/api/getsubsdetails",
        addtocart: "https://dev.samrantech.com/tangoapi/public/api/addtoCart",
        emailvalidate: "https://dev.samrantech.com/tangoapi/public/api/getemailvalidate",
        removecart: "https://dev.samrantech.com/tangoapi/public/api/removecard",
        getcountrylist: "https://dev.samrantech.com/tangoapi/public/api/getcountrylists",
        attendancereportbyMonth: "https://dev.samrantech.com/tangoapi/public/api/attendancereportbyMonth", 
        classreportbyDay: "https://dev.samrantech.com/tangoapi/public/api/classreportbyDay", 
        updatecartitem: "https://dev.samrantech.com/tangoapi/public/api/updatecartitem",  
        paymentconfirmurl: "https://cart.smart-bookings.com/bookingconfirm?cartid=", 
        upqtyitemurl: "https://dev.samrantech.com/tangoapi/public/api/upitemquantity",
        authwplogin: "https://dev.samrantech.com/wp-json/tatimetable/validateuser",
        udetailsbyemail: "https://dev.samrantech.com/tangoapi/public/api/userautoauthdetails",  
        caclupgradefee: "https://dev.samrantech.com/tangoapi/public/api/subsupgradefee",
        subcontinueemail: "https://dev.samrantech.com/tangoapi/public/api/subcontinueemail",
        getsmartconfig: "https://dev.samrantech.com/tangoapi/public/api/getsmartconfigdetails",
        tanamsstpkey: "pk_live_ynk6TtJYd1IVWDoRBM6lXokD00U9TKWowA",
        reqrestpassword: "https://dev.samrantech.com/tangoapi/public/api/usrestpassword",
        otpresetpwd: "https://dev.samrantech.com/tangoapi/public/api/otprestpassword",
        getcreditsdata: "https://dev.samrantech.com/tangoapi/public/api/getcreditsdata",
        validate_token: "https://dev.samrantech.com/tangoapi/public/api/validate-token",
    },
   
};
 
export default config;