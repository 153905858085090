import React, { Component } from 'react';

const stripe = require('stripe')('sk_test_pDVpSQ6uEQbXeneqkIhyO9ln00uZFo3OFp');


class Checkoutstripe extends Component {

  constructor(props){
    super(props);
    this.state = {
      userlogin:{
          username: '',
          password: '',
      },
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      userflag:1,
  }   
      
  }



   componentDidMount(){ 
    
   }


   

  render() {


    // This example sets up an endpoint using the Express framework.
// Watch this video to get started: https://youtu.be/rPR2aJ6XnAc.


  const session = stripe.checkout.sessions.create({
    line_items: [
      {
        price_data: {
          currency: 'usd',
          product_data: {
            name: 'T-shirt',
          },
          unit_amount: 2000,
        },
        quantity: 1,
      },
    ],
    mode: 'payment',
    success_url: 'http://localhost:4242/success.html',
    cancel_url: 'http://localhost:4242/cancel.html',
  });

    console.log(session);


    
    const text = 'adsfasdfasdf';
    return(

<div>
     

</div>



    );
  }
}

export default Checkoutstripe;
