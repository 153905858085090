import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Calendar, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Link to={{ pathname:'/viewsubscription',search:'?id='+title.subscription_id}}>View</Link>]}>
  <List.Item.Meta           
      title={<span><strong>{index+1}. {title.subname}</strong></span>}
      description={<div>Price: <strong>&pound;{title.paid_amount} </strong> <br /> Start Date: <strong>{title.subscription_startdate}</strong> | Next Renewal Date: <strong>{title.dateofnextrenewal}</strong> <br />Status: <strong>{title.stacstatus}</strong></div>}
 /> 
 </List.Item>    
  );
  return (
    <List>  
   {listItems}
    </List> 
  );
}


function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Subscription list" key="1">
    <Classlist classlists={props.classlists} />
    </Panel></Collapse><br />
     </div>
  );
}


class CompSubscriptions extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
      <div className="site-card-wrapper">
      <Spin tip="Loading..."  spinning={this.props.loadclassflag}> 
      <Courseclassesnew                
                classlists = {this.props.classlists}             
           />
           </Spin>

      </div></div></div>
     
     
     
    );
}
}
export default CompSubscriptions;