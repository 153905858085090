import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,List,PageHeader,Button, Statistic, Result, Row,Collapse,Spin } from 'antd';
import 'antd/dist/antd.css';
import {Elements,PaymentElement,useStripe, useElements,} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Link } from 'react-router-dom';


//const stripePromise = loadStripe('pk_test_x2Hh3hUl3YIMB8pISx8p8Di000S6RZrb4p');

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;


function Showbookingconfirm(props) {

  if (!props) {
    return null;
  }

  const classdata = props.cartdata;
  console.log(props.cartdata);
  return (
   <div>

     <Result
    status="success"
    title = ""
    subTitle= {"Your booking is complete ! Thank you for booking. Your booking ref number : " + props.cartdata.cartid + "  You will receive the confirmation email soon"}
    extra={[
      <a href={props.smartbooking.myaccurl}><Button type="primary" key="console">
        Go Myaccount
      </Button></a>,
       <a href={props.smartbooking.homeurl}><Button>Home</Button></a>,
    ]}
  />
     </div>
  );
}


function Loadcartitems(props) {


  if (!props) {
    return null;
  }

  const classdata = props.cartdata.cartitems;
  //const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.item_name}</strong></span>}
      description={<div>Item Qty: {title.item_qty}, Price: &pound;{title.item_price}</div>}
 /> 
 </List.Item>   
  );
  return (
    <List>  


      
    {listItems}</List> 
  );
}



class CompCheckoutconfirm extends  Component{

    constructor(props){
         super(props); 
    }

    



render(){

  
    return(              
      <div className="dashboard-common-checkout">
      <div className="box-model-manage-checkout">
      <div className="site-card-wrapper-checkout">

      

      <Showbookingconfirm 
         cartdata = {this.props.cartdata}
         smartbooking = {this.props.smartbooking}    
       />

      <PageHeader title="Order Summary">
      <Row type="flex">        
        <Statistic
          title="Total Amount"
          prefix="&pound;"
          value={this.props.cartdata.totalamount}
          style={{
            margin: '0 32px',
          }}
        />
         <Statistic
          title="Credits applied"
          prefix="&pound;"
          value={this.props.credits_pay}
          style={{
            margin: '0 32px',
          }} 
        />

        <Statistic
          title="Discount"
          prefix="&pound;"
          value={this.props.cartdata.discountamount}
          style={{
            margin: '0 32px',
          }}
        />
        <Statistic title="Grand Total" prefix="&pound;" value={this.props.cartdata.grand_total} />
      </Row>
    </PageHeader> 


    <Collapse activeKey={['1']} expandIconPosition="right" >
    <Panel header="Your Booking Details" key="1">
    <Loadcartitems 
    cartdata = {this.props.cartdata}
    />   

    </Panel>
    </Collapse>       
    
     
      </div></div></div>
     
     
     
    );
}
}
export default CompCheckoutconfirm;