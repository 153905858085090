import React, { Component } from "react";
import './CommonFooter.css';
import { Layout, } from 'antd';

const { Footer } = Layout;
class CommonFooter extends Component {
 
     constructor(props){
         super(props);
     }

    render() {
        
        return ( 
            <Footer style={{ textAlign: 'center' }}>©2022 www.tango-amistoso.co.uk</Footer>
        );
      }
    
}
    
CommonFooter.defaultProps = {
    headertitle: 'Ca header Text',
}
export default CommonFooter;