import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Calendar, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.coursename} - {title.stdatetext}</strong></span>}
      description={<div>@ {title.locationtext}, Tutor(s): {title.tutortext} <br />Attended the Class: {title.attendedflag}</div>}
 />
 
 </List.Item>
  );
  return (
   
        (classdata.length > 0) ?
        <List>{listItems}</List> 
        :
        <div>No Records Found</div>   
   
  );
}


function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Scheduled Classes today" key="1">
    <Classlist classlists={props.classlists} />
    </Panel></Collapse><br />
     </div>
  );
}


class Dashboard extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
      <div className="site-card-wrapper">

      <Courseclassesnew                
                classlists = {this.props.classlists}             
           />

      </div></div></div>
     
     
     
    );
}
}
export default Dashboard;