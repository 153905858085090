import React, { Component } from "react";
import "../css/fonts.css";
import { Checkbox,Layout, Menu,List,PageHeader, Spin, Statistic, Radio, Row,Col,Collapse,Button,DatePicker } from 'antd';
import 'antd/dist/antd.css';
import {Elements,PaymentElement,useStripe, useElements,} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {getconfigurlmode} from '../helpers/Apicommon';

//const stripePromise = loadStripe('pk_test_x2Hh3hUl3YIMB8pISx8p8Di000S6RZrb4p');
//const stripePromise = loadStripe('pk_live_ynk6TtJYd1IVWDoRBM6lXokD00U9TKWowA');

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Link to={{ pathname:'/assignstudents',search:'?id='+title.id}}>Students</Link>]}>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.stdatetext}</strong></span>}
      description={<div>@ {title.locationtext}, Tutor(s): {title.tutortext} <br />Status: {title.classstatus} |  No Participants: {title.totalstudents}</div>}
 />
 
 </List.Item>
  );
  return (
    <List>  
    {listItems}</List> 
  );
}

function Countrylists(props) {


  if (!props) {
    return null;
  }

  const classdata = props.countrylist;
  const selecqty = props.selval;
  //id="usrcountry" selval={userdata.country} handleinputchangenewuser={props.handleinputchangenewuser}
  const listItems = classdata.map((title,index) =>
        
          (selecqty == title.country_2_code) ?
          <option selected="selected" value={title.country_2_code}>{title.name}</option>
          :
          <option value={title.country_2_code}>{title.name}</option>
       
  );
  return (
    <select id={props.id} onChange={props.handleinputchangenewuser}>
      {listItems}
    </select>
  );
}


function Newuserregister(props){

  if (!props) {
    return null;
  }

  return (
    <div>
<div className="profile_info_left mt-10">
<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>First Name</span>  
               </div>
               <div className="manage_course_info">           
              <span>{props.userdetails.firstname}</span>             
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Last Name</span>  
               </div>
               <div className="manage_course_info">
               <span>{props.userdetails.lastname}</span>  
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Email</span>  
               </div>
               <div className="manage_course_info">
               <span>{props.userdetails.email}</span>  
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Phone Number</span>  
               </div>
               <div className="manage_course_info">
               <span>{props.userdetails.phone_number}</span>                
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 1</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress1" name="usrbiladdress1"  autoComplete="off"  value={props.userdetails.address_line1} onChange={props.handleinputexistinuseronchange} />
               <span className="erromessage" id="error_usrbiladdress1"></span>
               </div>   
           </div>
           </div>
           </div>
 
</div>


<div className="profile_info_right mt-10">



           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 1</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress2" name="usrbiladdress2"  autoComplete="off"  value={props.userdetails.address_line2} onChange={props.handleinputexistinuseronchange} />
               <span className="erromessage" id="error_usrbiladdress2"></span> 
               </div>   
           </div>
           </div>
           </div>
           
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>City</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrcity" name="usrcity"  autoComplete="off"  value={props.userdetails.city} onChange={props.handleinputexistinuseronchange} />
               <span className="erromessage" id="error_usrcity"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Postcode</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrpostcode" name="usrpostcode"  autoComplete="off"  value={props.userdetails.post_code} onChange={props.handleinputexistinuseronchange} />
               <span className="erromessage" id="error_usrpostcode"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Country</span>  
               </div>
               <div className="manage_course_info">
               <Countrylists countrylist={props.countrylist} id="usrcountry" selval={props.userdetails.country} handleinputchangenewuser={props.handleinputexistinuseronchange}/>
               <span className="erromessage" id="error_usrcountry"></span>
               </div>   
           </div>
           </div>
           </div>

</div>

<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
              
               <div className="manage_course_info terms_info">           
               <Checkbox id="terms" name="terms" onChange={props.handletermsconditionsonchange} />             
               </div>  
               <div className="manage_course_label">
               <span>{props.terms_conditions} I agree to the <a target="_blank" href={ (props.bookingsource == 'smtf') ? 'https://www.tango-fever.com/terms-and-conditions/' : 'https://www.tango-amistoso.co.uk/terms-and-conditions/'}>terms and conditions</a></span>  
               </div> 
           </div>
           </div>
           </div>

{

(props.attendeeflag == 1) ?

''
:
<div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style" onClick={props.handlsubmitexistinguser} disabled={props.terms_conditions === true ? false:true}>Proceed to Payment</button>        
          </div>
       

          </div>
          </div>
}



   </div>
      );


}

function Loadstdate(props){
  const dateFormat = 'YYYY-MM-DD';
  if (!props) {
    return null;
  }

  let itemdata = props.substdate
  let itemindex = props.itenindex;
  return (
    <DatePicker defaultValue={moment(itemdata.substdate, dateFormat)} onChange={(date, dateString)=>{props.handleonchangesubstdate(date, dateString,itemindex)}} />
  );
  


}


function ItemQtyDropdown(props) {

  if (!props) {
    return null;
  }

  const numbers = [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]; 
  const selecqty = props.selval;
  const itemdata = props.itemdata

  const listItems = numbers.map((number) =>
  (selecqty == number) ?     
  <option selected='selected' value={number.toString()}>
      {number}
    </option> :     
  <option value={number.toString()}>
      {number}
    </option>
  );
  return (
    <select id={props.itemid} onChange={(e)=>{props.handlonchangeitemqty(e,itemdata)}} >{listItems}</select>
  );
}


function Attendeeform(props){

  if (!props) {
    return null;
  }
  const attedeedata = props.itemdata.attendeelist; 
  const listItems = attedeedata.map((title,index) =>
 <tr>
  <td>{index+1}</td>
  <td><input type="text" id="afname" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}  /></td>
  <td><input type="text" id="alname" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></td>
  <td><input type="text" id="aemail" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></td>
  <td><UserCategory id="ausercategory" handleinputchangenewuser={props.handleonchangeattendee}/>  </td>
  </tr>
 );


 const listItems2 = attedeedata.map((title,index) =>
 <div>
<h3 className="attendance-details">Enter the Attendee {index+1} Details</h3> 
<div class="profile_info_left mt-10">
<div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>First Name</span></div>
               <div class="manage_course_info"><input type="text" id="afirstname" name="afirstname" value={title.firstname} autocomplete="off" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Last Name</span></div>
               <div class="manage_course_info"><input type="text" id="alastname" name="alastname" value={title.lastname} autocomplete="off" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}/></div>
            </div>
         </div>
      </div>
  </div>

  <div class="profile_info_right mt-10">



<div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Email</span></div>
               <div class="manage_course_info"><input type="text" id="aemail" name="aemail" value={title.email} autocomplete="off" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}/></div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Entry Level</span></div>
               <UserCategoryattendee cindex={props.cindex} index={index} id="ausercategory" selval={title.usercategory} handleonchangeattendee={props.handleonchangeattendee}/> 
            </div>
         </div>
      </div>


  </div>

  </div>
 );
  
  return (
    <div>{ listItems2 }    
    <span className="erromessage" id={"error_attendee_" + props.cindex }>&nbsp;</span>
    </div> 
  );



}

function UserCategory(props) {

  if(!props){
    return null;
  }

  const category =  ['Leader','Follower'];
  const selecqty = props.selval;
  const listItems = category.map((title) =>
  (selecqty == title) ?     
  <option selected="selected" value={title.toString()}>
     {title}
  </option>
  :     
  <option  value={title.toString()}>
     {title}
  </option>
  );
  return (
  <select id={props.id}  onChange={props.handleinputchangenewuser} >
     <option value="">--Select--</option>
    {listItems}</select>
  );
  }


  function UserCategoryattendee(props) {

    if(!props){
      return null;
    }
  
    const category =  ['Leader','Follower'];
    const selecqty = props.selval;
    const listItems = category.map((title) =>
    (selecqty == title) ?     
    <option selected="selected" value={title.toString()}>
       {title}
    </option>
    :     
    <option  value={title.toString()}>
       {title}
    </option>
    );
    return (
    <select id={props.id}  onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, props.index)}} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
    }


function Loadaddtionalattendees(props){

  if (!props) {
    return null;
  }
  var strdisp = '';
  const classdata = props.cartdata.cartitems; 
  const listItems = classdata.map((title,index) =>
   <div>
    <h2>Addtional Attendee for {title.item_name}</h2>    
    <Attendeeform 
      itemdata  = {title}
      cindex = {index}
      handleonchangeattendee = {props.handleonchangeattendee}
      />
   
   </div>

  );
  return (
    <div> 
    {listItems}
    <div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style" onClick={props.handlsubmitexistinguser}>Proceed to Payment</button>        
          </div>
       

          </div>
          </div>
    
    </div> 
  );

}


function Loadcartitems(props) {


  if (!props) {
    return null;
  }

  const classdata = props.cartdata.cartitems;
  //const selecqty = props.selval handlonchangeitemqty;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Button onClick={(e)=>{props.handleremoveitems(title)}}>X</Button>]}>

  {(title.item_category == "subscription")  ?
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.item_name}</strong></span>}
      description={<div>Item Qty: <strong>{title.item_qty}</strong>, Price: <strong>&pound;{title.item_price}</strong> <br /> <i>Your Subscription will start from  {title.substdate}. If you wish to change the starting date please select the date from tbe below </i><br /> Subscription Start Date: <Loadstdate substdate={title} itenindex = {index} handleonchangesubstdate={props.handleonchangesubstdate} /></div>}
 /> 
 :
 <List.Item.Meta           
      title={<span><strong>{index+1}.{title.item_name}</strong></span>}
      description={<div>Item Qty: <ItemQtyDropdown itemid="iqty" selval={title.item_qty} itemdata ={title}  handlonchangeitemqty={props.handlonchangeitemqty}  />, Price: &pound;{title.item_price} </div>}
 /> 

 }

 </List.Item>   



  );
  return (
    <List> 
    {listItems}</List> 
  );
}


function CheckoutFormnew(props){

  const stripe = useStripe();
  const elements = useElements();
  
  if (!props) {
    return null;
  }

  
  const cartdata = props.cartdata;
  const savedcards = props.usersavedcards;
  const acardid = props.activecardid;
  const payindent = props.stripepaymentintent;
  let cinpage = getconfigurlmode();
  const redirectpaymenturl = cinpage.paymentconfirmurl;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const cardlistdetails = savedcards.map((title,index) =>
      <Radio style={radioStyle} value={title.card_id}> {title.brand} card  ending with **** **** **** {title.last4} </Radio>
  );

  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    document.getElementById('error_checkout').innerHTML = 'Your Payment is being processed. Please wait....';
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectpaymenturl + cartdata.cartid,
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      //console.log(result.error.message);
      document.getElementById('error_checkout').innerHTML = result.error.message;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };


  const handleSubmit2 = async (event) => {


              event.preventDefault();

              if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
              }
              document.getElementById('error_checkout').innerHTML = 'Your Payment is being processed. Please wait....';
          

            const result = await stripe.confirmCardPayment(payindent.clientSecret,
            {
              payment_method: acardid, 
            });
            
            if (result.error) {             
              document.getElementById('error_checkout').innerHTML = result.error.message;
            } else {               

              if (result.paymentIntent.status === 'succeeded') {
                  window.location.href = redirectpaymenturl + cartdata.cartid + "&payment_intent=" + result.paymentIntent.id;
              }
            }
  };

  return (

    <div>
      <Radio.Group value={props.paycardflag} onChange={props.handlepaymentmethodsavedcard} >
        {cardlistdetails}
        <Radio style={radioStyle} value='newcard'>
         Pay with New Card
        </Radio>
        
      </Radio.Group>


        {
            (props.paycardflag == "newcard") ?
                <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button>Book Now</button>
                <div class="erromessage" id="error_checkout" ></div>
                </form>
            :
                <div>
                  <button onClick={handleSubmit2}>Book Now</button>
                  <div class="erromessage" id="error_checkout" ></div>
                  </div>
        }
   
    
    </div>
  );

}

function Showbillinginformation(props){

  if (!props) {
    return null;
  }

  const srdetails = props.userdetails;
  return (
    <List>  
    <List.Item>
    <List.Item.Meta           
        title={<span><strong>{srdetails.firstname + " " + srdetails.lastname}</strong></span>}
        description={<div>Email: {srdetails.email} <br /> Address : {srdetails.address_line1},{srdetails.city},{srdetails.post_code} </div>}
    /> 
    </List.Item>
    </List> 
  );

}


function Showyourbasket(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse defaultActiveKey={['1','2','3','4']} expandIconPosition="right" >
    <Panel header="your basket" key="1">
    <Loadcartitems 
        cartdata = {props.cartdata}
        handleremoveitems ={props.handleremoveitems}
        handleonchangesubstdate = {props.handleonchangesubstdate}
        handlonchangeitemqty = {props.handlonchangeitemqty}        
    />
    </Panel>
    <Panel header="Your Billing Details" key="2">

        {
              (props.showpaymentform == true) ?

                <Showbillinginformation userdetails ={props.userdetails}  />
              :

                <Newuserregister  
                        userdetails = {props.userdetails} 
                        handleinputexistinuseronchange={props.handleinputexistinuseronchange}
                        handlsubmitexistinguser = {props.handlsubmitexistinguser}
                        countrylist = {props.countrylist}
                        attendeeflag = {props.cartdata.attendeeflag}
                        bookingsource = {props.bookingsource}
                        terms_conditions = {props.terms_conditions}
                        handletermsconditionsonchange = {props.handletermsconditionsonchange}
                        />
          
        }

      
        
    </Panel> 



    {

(props.showpaymentform == true)  ?           
  ''
:
  (props.cartdata.attendeeflag == 1 && props.userflag != 2)  ?

      <Panel header="Addtional Attendee(s)" key="3">
      <Loadaddtionalattendees 
        cartdata = {props.cartdata}
        handleremoveitems ={props.handleremoveitems}
        handleonchangesubstdate = {props.handleonchangesubstdate}
        handlonchangeitemqty = {props.handlonchangeitemqty}
        handleonchangeattendee = {props.handleonchangeattendee}                
        handlsubmitexistinguser = {props.handlsubmitexistinguser}
      />
      </Panel>
  :
    ''
}

        {

            (props.showpaymentform == true) ?  

                <Panel header="Payment" key="4">
                <Elements stripe={props.stripepromise} options={props.stripepaymentintent} >
                      <CheckoutFormnew 
                          cartdata={props.cartdata}  
                          usersavedcards = {props.usersavedcards} 
                          paycardflag = {props.paycardflag}
                          handlepaymentmethodsavedcard = {props.handlepaymentmethodsavedcard}
                          activecardid = {props.activecardid} 
                          stripepaymentintent = {props.stripepaymentintent}
                        />
                      </Elements>
                </Panel> 

            :

            ''          
        }   

  </Collapse>
     </div>
  );
}


class CompCheckoutbasketlogged extends  Component{

    constructor(props){
         super(props); 
    }

    componentWillReceiveProps(props) {

      if (props.cartdata.api_key) {
         
          loadStripe(props.cartdata.api_key).then(res=>{
            this.stripePromise = res
          })
      }    
     
  }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage"> 
      <div className="site-card-wrapper checkoutbasket logout">
{/* if(this.props.total_credits){ */}
  {
    !this.props.subscription_booking && <PageHeader title="Pay using Credits">
    <Row type="flex">   
      <Statistic
          title="Credits balance"
          prefix="&pound;"
          value={this.props.total_credits}
          style={{
            margin: '0 32px',
          }}
        />    
        <div class="manage_course_info credits_pay">
          <label>Enter the credits to pay</label>
         <input type= "number" id="credits_pay" name="usrbiladdress1"  autoComplete="off"  value={this.props.credits_pay} onChange={this.props.handleinputcreditsonchange} />
         {this.props.showButton &&  <span>Please enter less than credit balance</span>}           </div>
       
        <div class="login-btn credits_login_btn">
          <button class="login-btn-style" onClick={this.props.handlsubmitcredits}>Apply</button>        
        </div>
     

    
       </Row>
      </PageHeader> 
  }
       
{/* }  */}
      <PageHeader title="Order Summary">
      <Row type="flex">        
    
        <Statistic
          title="Total Amount"
          prefix="&pound;"
          value={this.props.cartdata.totalamount}
          style={{
            margin: '0 32px',
          }}
        />
        
         {this.props.showCredits && <Statistic
          title="Credits applied"
          prefix="&pound;"
          value={this.props.credits_pay}
          style={{
            margin: '0 32px',
          }}
        />
}
        <Statistic
          title="Discount"
          prefix="&pound;"
          value={this.props.cartdata.discountamount}
          style={{
            margin: '0 32px',
          }}
        />
        <Statistic title="Grand Total" prefix="&pound;" value={this.props.cartdata.grand_total} />
      </Row>
    </PageHeader>  


    <Spin tip="Loading..."  spinning={this.props.loadclassflag} >

     {
        (this.props.cartdata.cartcount == 0) 
                
        ?
          <div>No Items in the basket</div>

        :

        <Showyourbasket 
          userflag = {this.props.userflag}
          handleonchangeuserradio = {this.props.handleonchangeuserradio}
          cartdata = {this.props.cartdata}
          userdetails = {this.props.userdetails}
          stripepaymentintent = { this.props.stripepaymentintent }
          usersavedcards = {this.props.usersavedcards}
          paycardflag = {this.props.paycardflag}
          handlepaymentmethodsavedcard = {this.props.handlepaymentmethodsavedcard}
          showpaymentform = {this.props.showpaymentform} 
          handleinputexistinuseronchange = {this.props.handleinputexistinuseronchange}
          handlsubmitexistinguser = {this.props.handlsubmitexistinguser}
          activecardid = {this.props.activecardid} 
          handleremoveitems = {this.props.handleremoveitems}
          countrylist = {this.props.countrylist}
          handleonchangesubstdate = {this.props.handleonchangesubstdate}
          handlonchangeitemqty = {this.props.handlonchangeitemqty}
          handleonchangeattendee = {this.props.handleonchangeattendee}
          validateattendeedata = {this.props.validateattendeedata}
          stripepromise = {this.stripePromise}
          bookingsource = {this.props.bookingsource}
          terms_conditions = {this.props.terms_conditions}
          handletermsconditionsonchange = {this.props.handletermsconditionsonchange}
        />

     }


     
 </Spin>

    
     

      </div></div></div>
     
     
     
    );
}
}
export default CompCheckoutbasketlogged;