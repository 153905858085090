import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Viewcredits.css';
import "../../css/fonts.css";
import { Layout,Spin } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompViewcredits from '../../components/Viewcredits';
import { addtocartcheckout,getcreditsdata,getsubslists,calculateupgradefee,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Redirect } from "react-router-dom";
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE} from '../../constants';
import { Helmet } from 'react-helmet';

const {  Content,  Sider } = Layout;
const databreadcrumb = [
  {
    title: 'Dashboard',
    linkurl: ''
  },
  
];

class Viewcredits extends Component {

  constructor(props){
    super(props); 

    this.state = {
      collapsed: false,
      loggedstatus:'logged',
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      filterparams:{
      
        classstatus: 1,
      },
      classlists: [],
      loadclassflag: false,
      subsid: 0,
      subsplandeatils: [],
      subspaymentplanhistory: [],
      renewaldata:{       
        item_id:'',
        item_name:'',
        item_price:'',
        item_qty:'',
        item_category:'',
      
      },
      subscriptionpartnerid: 0,
      cartid:'',   
      subscriptionlists: [],  
      upgradeplandata:{       
        item_id:'',
        item_name:'',
        item_price:'',
        item_qty:'',
        item_category:'',
       
        old_plan_id:'',      
      }, 
    
      upplandetails:[],
      upgpplanmessage: '',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta',   

    };

    this.handleLogout = this.handleLogout.bind(this);  
   
 
  } 


  componentDidMount(){ 

      this.loadCurrentUser();  

  }


getsubscriptionlists(spartnerid){

 
  let searchprops = this.state.filterparams;   
  const data={
    subcripiton_status: '',
    partner_id: spartnerid , 
    sub_ctype: 'class',     
  }     
  getsubslists(data).then(response => {
        if(response.status == 'success'){           
          this.setState({subscriptionlists: response.subscription_plans});
        }        
    })
}

  loadCurrentUser(){
    //console.log(subid)
    let locstatus = false;
    let bsource = this.state.bookingsource; 

    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    
    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);      
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    } 

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata});     
      this.getclasslists(udata.userid,bsource);        
    }

    if (localStorage.getItem(TA_CART_ID) !== null) {
      this.setState({cartid: localStorage.getItem(TA_CART_ID)});
    }

     
    

  }

  
  getclasslists(userid,bsource){

    this.setState({loadclassflag: true}); 
    //let bsource = this.state.bookingsource;  
    let searchprops = this.state.filterparams;   
    const data={
      bsource: bsource,
      student_id: userid,        
    }     
    getcreditsdata(data).then(response => {
          if(response.status == 'success'){           
            this.setState({subsplandeatils: response.credits});
            this.setState({subspaymentplanhistory: response.credits_used});

            let subsplan =  response.subs_plan;
            let sdata  = this.state.renewaldata;
            sdata.item_qty = 1;
            sdata.item_category = 'subscriptionrenewal';
           
            this.setState({renewaldata: sdata});
           

          }
          this.setState({loadclassflag: false}); 
      })
}



  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
           // this.setState({loadingthemconfig: true});                      
          }            
      }).catch(error => {
        
      });
  
   }
  
  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA);  
    localStorage.removeItem(TA_CART_ID);     
    this.props.history.push('/');     
}


  render() { 
   
    let loggedflag = this.state.loggedstatus;
    if (loggedflag != 'logged') {
        return <Redirect to='/' />
    }

    return(

      (this.state.loadingthemconfig == false) 
      ?
    
    <Layout>   
    <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title></Helmet>       
   <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartid} />
   <Layout>
   <Sider breakpoint="lg"  collapsedWidth="0">
   <Leftmenu />
   </Sider>
   <Layout>    
     <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
     <PageBreadCrumb 
     databreadcrumb = {databreadcrumb}
     
     />
            
    <CompViewcredits 
        classlists = {this.state.classlists}
        loadclassflag = {this.state.loadclassflag}
        subsplandeatils = {this.state.subsplandeatils}
        subspaymentplanhistory = {this.state.subspaymentplanhistory}
        subscriptionlists = {this.state.subscriptionlists}
    />

     </Content>
    <CommonFooter />
   </Layout>
 </Layout>
 </Layout>

:
<Spin tip="Loading..." />
  
    );
  }
}

export default Viewcredits;
