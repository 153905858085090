import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select ,Icon,Spin,Checkbox,Calendar, DatePicker, Tag,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import uploadlogo from '../images/upload-img.png';
import moment from 'moment';
import { Link } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { RangePicker } = DatePicker;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function Fortest(props) {

  const testfor =  ['Myself','Mother','Father','Spouse','Son','daughter','Friend','Other'];

  const selecqty = props.selval;
  const listItems = testfor.map((title) =>
  (selecqty == title) ?     
  <option selected='selected' value={title.toString()}>
     {title}
  </option>
  :     
  <option value={title.toString()}>
     {title}
  </option>
  );
  return (
  <select id={props.id} dataname={props.dataname}  onChange={props.fnonChange} >
     <option value="">--Select--</option>
    {listItems}</select>
  );
  }

  function Gender(props) {

    const gender =  ['Male','Female'];
  
    const selecqty = props.selval;
    const listItems = gender.map((title) =>
    (selecqty == title) ?     
    <option selected='selected' value={title.toString()}>
       {title}
    </option>
    :     
    <option value={title.toString()}>
       {title}
    </option>
    );
    return (
    <select id={props.id} dataname={props.dataname}  onChange={props.fnonChange} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
    }

    function ReviewStatusdrop(props) {

      const rstatus =  ['New','in review','Query','Completed','Not approved'];
    
      const selecqty = props.selval;
      const listItems = rstatus.map((title) =>
      (selecqty == title) ?     
      <option selected='selected' value={title.toString()}>
         {title}
      </option>
      :     
      <option value={title.toString()}>
         {title}
      </option>
      );
      return (
      <select id="rvstatus" value={props.reveiwstatus} onChange={props.handleinputonchange}>
         <option value="">--Select--</option>
        {listItems}</select>
      );
      }


   function Reportstatusdrop(props) {

        if (!props) {
          return null;
        } 

        const rstatus =  ['Positive','Negative','Non Conclusive'];      
        const selecqty = props.selval;       
        const listItems = rstatus.map((title) =>
        (selecqty == title) ?     
        <option selected='selected' value={title.toString()}>
           {title}
        </option>
        :     
        <option value={title.toString()}>
           {title}
        </option>
        );
        return (
        <select id="rpt_status" onChange={props.handleinputonchangereport}>
           <option value="">--Select--</option>
          {listItems}</select>
        );
        }

        function Rptprofiledocuments(props) {

          if (!props.documentList) {
            return null;
          } 

          const rpdocuments =  props.documentList;  
          const selecqty = props.selval;
          const listItems = rpdocuments.map((title) =>         
          <div className="box-img">
          <img src={title.url}/>
          <div className="box-img-content">
          <Button type="primary" onClick={()=>{props.hadlemodalpopup(title)}}>View</Button>
          <a class="primary" href={title.url} target="_blank">View in browser</a>
          </div>
          </div>
          );
          return (
            <div className="manage_course_info_upload">
             {listItems}
            </div>
          );
          }

          function Rptspecimendocuments(props) {

            if (!props.specimenList) {
              return null;
            } 
  
            const rpdocuments =  props.specimenList;  
            const selecqty = props.selval;
            const listItems = rpdocuments.map((title) =>         
            <div className="box-img">
            <img src={title.url}/>
            <div className="box-img-content">
            <Button type="primary" onClick={()=>{props.hadlemodalpopup(title)}}>View</Button>
            <a class="primary" href={title.url} target="_blank">View in browser</a>
            </div>
            </div>

            );
            return (
              <div className="manage_course_info_upload">
               {listItems}
              </div>
            );
            }


            function ReveiwComments(props) {

              if (!props.reveiwcomments) {
                return null;
              } 
    
              const rpdocuments =  props.reveiwcomments;             
              const listItems = rpdocuments.map((title) =>         
              <Card bordered={true}>
              <p>{title.comments}</p>
               <p className="com-ilatic">{ title.added_role_type}({ title.added_by}) - { title.days}</p>              
                      </Card>
  
              );
              return (
                <div>
                 {listItems}
                </div>
              );
              }
  



function Personalform(props){
  if(!props){
    return null;
  }
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const { Option } = Select;
  const dateFormat = 'YYYY-MM-DD';
  
  let date = '2015-01-01';
  let userperdata = props.persondata;

  return ( <div> 	<Collapse expandIconPosition="right" defaultActiveKey={['1']} >
  <Panel header="Test Person Information" key="1">

   <div className="profile_info_left mt-10">
          <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> Name</span>  
               </div>
               <div className="manage_course_info">
               <span> {userperdata.first_name} {userperdata.last_name}</span> 
               </div>   
           </div>
           </div>
           </div> 
      <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> Email</span>  
               </div>
               <div className="manage_course_info ">
               <span> {userperdata.email}</span>   </div>   
           </div>
           </div>
           </div>
      <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> Phone</span>    
               </div>
               <div className="manage_course_info ">
               <span> {userperdata.phone}</span>     </div>   
           </div>
           </div>
           </div>
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> Gender</span>    
               </div>
               <div className="manage_course_info ">
               <span> {userperdata.gender}</span>     </div>   
           </div>
           </div>
           </div>
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> DOB</span>    
               </div>
               <div className="manage_course_info ">
               <span> {userperdata.dob}</span>     </div>   
           </div>
           </div>
           </div>
     
       
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div>
               <span> <strong>Address</strong> </span>  
               </div>
               <div >
               <span> {userperdata.address1} {userperdata.address2} {userperdata.city} {userperdata.country}{userperdata.postcode} </span>   </div>   
           </div>
           </div>
           </div>
      
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span>Country zone</span>  
               </div>
               <div className="manage_course_info">
               <span>{userperdata.country_zone} </span> 
               </div>   
           </div>
           </div>
           </div> 
   </div>
 <div className="profile_info_right mt-10">
 <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> PLF Number</span>  
               </div>
               <div className="manage_course_info">
               <span> <Tag color="magenta">{userperdata.plf_ref}</Tag> </span> 
               </div>   
           </div>
           </div>
           </div> 
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span> Date or Arrival</span>  
               </div>
               <div className="manage_course_info">
               <span> <Tag color="purple">{userperdata.dateofarrival}</Tag> </span> 
               </div>   
           </div>
           </div>
           </div> 
           
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span>Vaccination Status</span>  
               </div>
               <div className="manage_course_info">
               <span>{userperdata.vaccination_status} </span> 
               </div>   
           </div>
           </div>
           </div> 
     
           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span>Passport No</span>  
               </div>
               <div className="manage_course_info">
               <span> <Tag color="green">{userperdata.passport_no}</Tag> </span> 
               </div>   
           </div>
           </div>
           </div> 

           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span>Ethinicity</span>  
               </div>
               <div className="manage_course_info">
               <span> {userperdata.ethinicity} </span> 
               </div>   
           </div>
           </div>
           </div> 

           <div className="row">
           <div className="filer_course_2">
           <div class="top_header_filer">
               <div className="manage_course_label">
               <span>Flight No</span>  
               </div>
               <div className="manage_course_info">
               <span> <Tag color="yellow">{userperdata.flight_no}</Tag> </span> 
               </div>   
           </div>
           </div>
           </div> 
        
</div>
 

  </Panel>
</Collapse><br/>


  <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Document Upload" key="1">
	<row>
	   <div className="document_info_left mt-10">
			<div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label_full">
                 <span>PERSONAL PROOF</span>  
                 </div>
			 
			  <Rptprofiledocuments documentList={props.documentList} hadlemodalpopup={props.hadlemodalpopup} />


    	</div>
		</div>
	    </div>
             </div>
	   <div className="document_info_right mt-10">
	   <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label_full">
                 <span>SPECIMEN PROOF</span>  
                 </div>			 
	 <Rptspecimendocuments specimenList={props.specimenList} className="modal-popup" hadlemodalpopup={props.hadlemodalpopup} />
    	</div>
		</div>
	    </div>
      <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span> Specimen Barcode :</span>  
                 </div>
                 <div className="manage_course_info ">
		          <span>	<input id="barcodeval" value={userperdata.specimen_barcode}  onChange={props.handleinputbarcodechange}/>  <Button type="primary" className="btm-next" onClick={props.handlevalidatebarcode}>Save</Button></span>
              <div class="erromessage" id="error_barcode"></div>
              <div class="succesmessage" id="success_barcode"></div>
                 </div>   
             </div>
             </div>
             </div>

	   </div>

    
	   </row>
	
	    </Panel>
  </Collapse><br/>

  <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Review Status" key="1">
	<row>
	   <div className="profile_info_left mt-10">
     <Spin tip="Loading..." spinning={props.reviewspinloader}>
        
        <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span> Status :</span>  
                 </div>
                 <div className="manage_course_info ">
				<ReviewStatusdrop selval="" reveiwstatus={props.reviewstautdrop.reveiwstatus}  handleinputonchange={props.handleinputonchange} />
        <div class="erromessage" id="error_rvstatus"></div>
                 </div>   
             </div>
             </div>
             </div>

             <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
            
                 <div className="manage_course_label">
                 <span> Comments :</span>  
                 </div>
                 <div className="manage_course_info ">
                 <TextArea rows={4} id="txtreveiwcomments" value={props.reviewstautdrop.reivewstcmtns} onChange={props.handleinputonchange} />
                 <div class="erromessage" id="error_rvcomments"></div> 
                 </div>  
                 
             </div>
             </div>
             </div>
             <div className="save_button">
                   <Button type="primary" className="btm-next" onClick={props.hanleupdatereviewstatus}>
                     SUBMIT
                    </Button>
                </div>
</Spin>

             </div>
	   <div className="profile_info_right mt-10">
     <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
             <div className="manage_course_label_full">
                 <span> Current Review Status : <Tag color="blue">{userperdata.review_status}</Tag> </span>  
                 </div><br/>
                 {/* <div className="manage_course_label_full">
                 <span> Specimen Barcode : <Tag color="blue">{userperdata.specimen_barcode}</Tag> </span>  
                 </div><br/> */}
                 <div className="manage_course_label_full">
                 <span> Comments :</span>  
                 </div>
             </div>
             </div>
             </div>
          <ReveiwComments reveiwcomments={props.reveiwcomments} />
	   </div>
	  
	   </row>
	
	    </Panel>
  </Collapse>

  <br />


  <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Report Generation" key="1" className="report-generation">
	<row>

  <Spin tip="Updating please wait..." spinning={props.reprtspingloader}>
  <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span> Status :</span>  
                 </div>
                 <div className="manage_course_info ">
				<Reportstatusdrop selval = {props.reptstatusdep.rptstatus}  handleinputonchangereport={props.handleinputonchangereport}/>
        <div class="erromessage" id="error_rptstatus"></div>
                 </div>   
             </div>
             </div>
             </div>
  <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label_full">
                 <span> Comments : (<Icon type="info-circle" /> This comments will appear in the original report)</span> 
                 <Button type="link" icon="download" onClick={props.handleloaddefcomments}> Load Default Comments</Button>
                 </div>
                 <div>
                 <TextArea id="rtp_comments" placeholder="Please enter your report comments here"  rows={10}  value={props.reptstatusdep.rptcomments} onChange={props.handleinputonchangereport}  />
                 <div class="erromessage" id="error_rptcomments"></div>
                 </div>   
             </div>
             </div>
             </div>


	   <div className="save_button_center">
           <Button type="primary" className="btm-dashboard" onClick={props.hanleupdaterreportstatus} >SUBMIT</Button>
           
           {
             (userperdata.report_status == '')              
             ?
               <Button type="primary" className="btm-review"  disabled>Generate Report</Button>
             :
              <Button type="primary" className="btm-review"  onClick={props.handlegeneratereport}>Generate Report</Button>
           }
          
          {
             (userperdata.rptgeneratedflag == 0)              
             ?
             <Button type="primary" className="btm-next" disabled >View / Download</Button>     
             :            
             <a target={"_blank"} className="btm-nextdn" href={ userperdata.reportgenurl } >View / Download</a>  
            
           }


          
     
     </div>
                
             </Spin>   
             {

(userperdata.rptgeneratedflag == 1)   ?

<div>

<div className="document_info_left mt-10">
	   <div className="row">
     <Card bordered={true} className="yellow-lable">

<p><input type="checkbox" onChange={props.handlesendtouserchange}></input> Send Report to User

{

(props.senttoreportflag == true)
?
<Button type="primary" className="btm-next" onClick={props.handlesendreprot}>Send</Button>
:
<Button type="primary" disabled className="btm-next" >Send</Button>
}


</p>

{

(userperdata.report_send_flag == true)
?
<p>Report sent on : <b> {userperdata.reportsenddatetime}</b></p>
:
''
}

        </Card>
     
       
       </div>
       </div>
                <div className="document_info_right mt-10">
	   <div className="row">
     <Card bordered={true} className="red-lable">
  <p className="com-ilatic-left">Last Report Generate On <strong>{ userperdata.reportgendatetime }</strong></p> 
  </Card>

       </div>
       </div>

</div>
:
       <div></div>         
                
}
	   </row>
	
	    </Panel>
  </Collapse><br/>

</div> );
   

}
  
class CompAddnewtest extends  Component{

    constructor(props){
         super(props); 
         this.state = {
          previewVisible: false,
          previewImage: '',
          previewTitle: '',
          fileList: [
          ],
        };

        this.hadlemodalpopup = this.hadlemodalpopup.bind(this);
    }

    handleOk = e => {     
      this.setState({ previewVisible: false });
    };
  
    handleCancel = e => {     
      this.setState({ previewVisible: false });
    };

  hadlemodalpopup(udata){    
    this.setState({previewImage: udata.url, previewVisible:true });
  }
        
render(){
  
    return(              
      <div className="dashboard-common">
        <div className="box-model-addtest">
       <div className="site-card-wrapper">
     <div className="filter_top" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
            <div className="top_header_filer">
               
                    <div className="dashboard_button">
                  
					<Link to="/managetests" >
					  <Button type="primary" className="btm-search" >
                     BACK TO TEST REPORT
                    </Button>
                    </Link>
                </div>
            </div>       
      

        <div className="profile_info_left mt-10">
	   	 <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span>Test Request From</span>  
                 </div>
                 <div className="manage_course_info ">
				 <span>{ this.props.spuserdata.name }</span>
                 </div>   
             </div>
             </div>
             </div>
             <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span> Test Request for :</span>  
                 </div>
                 <div className="manage_course_info ">
				 <span><Tag color="orange">{this.props.persondata.test_for}</Tag></span>
                 </div>   
             </div>
             </div>
             </div>
             
</div>
<div className="profile_info_right mt-10">
	   	
<div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span>Submitted Date:</span>  
                 </div>
                 <div className="manage_course_info ">
				 <span>
           {this.props.persondata.submitted_date}
         </span>
                 </div>   
             </div>
             </div>
             </div>
             <div className="row">
             <div className="filer_course_2">
             <div class="top_header_filer">
                 <div className="manage_course_label">
                 <span> Review Status :</span>  
                 </div>
                 <div className="manage_course_info ">
				 <span><Tag color="blue">{this.props.persondata.review_status}</Tag></span>
                 </div>   
             </div>
             </div>
             </div>
</div>

</div> 

         <div className="instruction">  
		 
    
        <Personalform 
           fnaddnewperson={this.props.fnaddnewperson}
           fnadddocupload={this.props.fnadddocupload}
           fnaddspecimenupload={this.props.fnaddspecimenupload}
            persondata={this.props.persondata}  
            handleinputonchange={this.props.handleinputonchange} 
            handlefromdatechange={this.props.handlefromdatechange}
            handlefromdatetimechange={this.props.handlefromdatetimechange}
            files={this.props.files}
            docfiles={this.props.docfiles}
            fileList={this.props.fileList}
            barcodeval={this.props.barcodeval}
            // handleCancel={this.props.handleCancel}
            // handlePreview={this.props.handlePreview}
            // handleChange={this.props.handleChange}
            handleDocCancel={this.props.handleDocCancel}
            handleDocPreview={this.props.handleDocPreview}
            handleDocChange={this.props.handleDocChange}
            specimenfiles={this.props.specimenfiles}
            specimenList={this.props.specimenList}
            documentList={this.props.documentList}
            handleSpecimenCancel={this.props.handleSpecimenCancel}
            handleSpecimenPreview={this.props.handleSpecimenPreview}
            handleSpecimenChange={this.props.handleSpecimenChange}          
            toggle = {this.props.toggle}
            hadlemodalpopup ={this.hadlemodalpopup}                     
            hanleupdatereviewstatus = {this.props.hanleupdatereviewstatus}
            handlevalidatebarcode={this.props.handlevalidatebarcode}
            handleinputonchange = {this.props.handleinputonchange}
            reviewspinloader = {this.props.reviewspinloader}
            reveiwcomments = {this.props.reveiwcomments}
            reptstatusdep = {this.props.reptstatusdep}
            handleinputonchangereport = {this.props.handleinputonchangereport}
            hanleupdaterreportstatus= {this.props.hanleupdaterreportstatus}
            reprtspingloader = {this.props.reprtspingloader}
            handleloaddefcomments = {this.props.handleloaddefcomments}
            handlegeneratereport = {this.props.handlegeneratereport}
            senttoreportflag = {this.props.senttoreportflag}
            handlesendtouserchange = {this.props.handlesendtouserchange}
            handlesendreprot = {this.props.handlesendreprot}
            reviewstautdrop={this.props.reviewstautdrop}
            handleinputbarcodechange={this.props.handleinputbarcodechange}

            />
            <Modal
          title="Preview"
          visible={this.state.previewVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="50%"
        >
          <div style={{ width: '100%', height: '100%'}}><img src={this.state.previewImage} ></img></div>
         
        </Modal>
		                 
  
  
         </div>
  </div>  </div>   
      </div>
    );
}
}
export default CompAddnewtest;