import React, { Component } from "react";
import '../pages/login/PageUserLogin.css';
import "../css/fonts.css";
import { img } from  '../images/logo-mobile.png';
import { Spin } from 'antd';
import { Link } from 'react-router-dom'

class UserLogin extends  Component{

    constructor(props){
         super(props); 
    }



render(){

    return(
        <div class="login-page">
            <div class="conatiner">
            <div class="login-box">
        <div class="login-left">
        <div class="login-container">
		
        <div class="logo-login"><img src={this.props.smarbookingconfig.logopath}  /></div>

          <h3 class="login-heading">LOGIN</h3>
		  
		  
          <div class="row">
          <div class="row-label">
          <label>Username</label>
          </div>
          <div class="row-label">
          <input type="text" class="input-border" name="email" id="loggemail" className="login-textbox" value={this.props.userdata.username} onChange={this.props.fnhandleonchage}  />
          <div class="erromessage" id="error_email"></div>
          </div>
          </div>

          <div class="row">
          <div class="row-label">
          <label>Password</label>
          </div>
          <div class="row-label">
          <input type="password" class="input-border" name="passowrd" id="loggpassword" className="login-textbox" value={this.props.userdata.password} onChange={this.props.fnhandleonchage} />
          <div class="erromessage" id="error_password" ></div>
          </div>
          </div>

          <div class="row">
          <div class="login-mt">
         
           {(this.props.showloading == true)?
                  <div>
                     <Spin tip="Please wait... Logging..." />
                  </div>
                  :
                  ''
                  } 
          <div class="login-btn">
       <button class="login-btn-style" onClick={this.props.fnonloginsubmit} >Login</button>        
          </div>
          <div class="forgot-pwd">
          <a className="button" href={"/resetpassword"}>Forgotten Password?</a>
          </div> 
       

          </div>
          </div>
        </div>
        </div>
		<div class="login-right">
		<div class="login-content">
        <h3>{this.props.smarbookingconfig.sitetitle}</h3>
        <p>{this.props.smarbookingconfig.short_desc}</p>
		</div></div>
</div>
        </div>
        </div>
    );
}
}
export default UserLogin;