import React, { Component } from "react";
import './CommonFooter.css';
import 'antd/dist/antd.css';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';



function Breadcrumblist(props){
  if (!props) {
    return null;
  } 
  const data =props.databreadcrumb;
  const listItems = data.map((rdata) =>
       <Breadcrumb.Item>{rdata.title}</Breadcrumb.Item>
  );
  
  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
              {listItems}
            </Breadcrumb>
  );

}

class PageBreadCrumb extends Component {
 
     constructor(props){
         super(props);
     }

    render() {
      return(
       <Breadcrumblist 
       
       databreadcrumb = {this.props.databreadcrumb}
       />

      );
      }
    
}    

export default PageBreadCrumb;