import React, { Component } from "react";
import "../css/fonts.css";
import { Spin,List,Collapse } from 'antd';
import 'antd/dist/antd.css';


const { Panel } = Collapse;
const dateFormat = 'DD-MM-YYYY';

function Creditslist(props) { 


  if (!props) {
    return null;
  }

  const classdata = props.subspaymentplanhistory;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.enty_type}</strong></span>}
      description={<div>-<strong>&pound;{title.credit_value}</strong> | Debited on  <strong>{title.log_datetime}</strong> </div>}
 /> 
 </List.Item> 
  ); 
  return (
    <List>{listItems}</List> 
  );
}

function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Used Logs" key="1">
    <Creditslist subspaymentplanhistory={props.subspaymentplanhistory} />
    </Panel></Collapse><br />
    <br />

     </div>
  );
}


class Compviewcredits extends  Component{

    constructor(props){
         super(props); 
    }

render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
     <div className="site-card-wrapper">

     <Spin tip="Loading..."  spinning={this.props.loadclassflag}> 

      <div className="filter_top" style={{ padding: 10, background: '#fff', marginBottom: 10}}>
          <div className="top_header_filer_heading">
    
           <div className="row"> 
           <div className="filer_course_2">
           <div className="top_header_filer_heading">
           <List>  
    <List.Item>
  <List.Item.Meta           
      title={<span>Credit Balance:  <strong> &pound;{this.props.subsplandeatils.credit_value}</strong></span>}
     
 /> 
 </List.Item>    
    </List> 
           </div>
           </div>
           </div> 
          </div>       
      </div>  


      <Courseclassesnew                
                Creditslists = {this.props.Creditslists}  
                subspaymentplanhistory = {this.props.subspaymentplanhistory}  
                handleonchangesubstdate = {this.props.handleonchangesubstdate}
                handlerenewalprocess = {this.props.handlerenewalprocess} 
                subsplandeatils = {this.props.subsplandeatils}  
           />
           </Spin>

      </div></div></div>
     
     
     
    );
}
}
export default Compviewcredits;