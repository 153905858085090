import React, { Component } from 'react';
import UserLogin from '../../components/userlogin';
import { Layout, Menu, Icon,Breadcrumb,Avatar, Row, Col,Dropdown,Badge,notification,Spin } from 'antd';
import 'antd/dist/antd.css';
import './PageUserLogin.css';
import logo from '../../images/xca-logo-black.png';
import "../../css/fonts.css";
import { userlogin,getloggeduser,getsmartconfiginfo } from '../../helpers/Apicommon';
import { CA_ACCESS_TOKEN,CA_LOOGED_EMAIL,CA_LOOGED_USER_DATA,sam_adminloggedstatus,BOOKING_SOURCE } from '../../constants';
import { Redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';

const queryString = require('query-string');
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

class PageUserLogin extends Component {

  constructor(props){
    super(props);
    this.state = {
      userlogin:{
          username: '',
          password: '',
      },
      bookingsource: 'smta',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
  }   
  this.handleinputonchange = this.handleinputonchange.bind(this);
  this.handleloginSubmit = this.handleloginSubmit.bind(this);
  
  }


  clearallerrormesssage(){
    document.getElementById('error_email').innerHTML = '';
    document.getElementById('error_password').innerHTML = ''; 
    this.setState({logmessage: ''});    
  }

   handleinputonchange(event) {
      
     let elid = event.target.id;
     let cdata = this.state.userlogin;    
     if(elid == "loggemail"){  
       document.getElementById('error_email').innerHTML = ''; 
       cdata.username = event.target.value;            
     }
     if(elid == "loggpassword"){  
       document.getElementById('error_password').innerHTML = '';  
       cdata.password = event.target.value;            
     }       
     this.setState({userlogin: cdata});   
    
  }

  handleloginSubmit() {
 
    this.clearallerrormesssage(); 
    let cdata = this.state.userlogin;
     let validateform = 1; 
     if(cdata.username.trim()== ""){  
      document.getElementById('loggemail').focus();                   
      document.getElementById('error_email').innerHTML='Please enter the username';
      validateform = 0;
     }
     else if(cdata.password.trim()== ""){  
         document.getElementById('loggpassword').focus();                   
         document.getElementById('error_password').innerHTML='Please enter your password';
         validateform = 0;
     }
  
     if(validateform == 1){
       this.setState({showloading: true});    
       this.logintooauth()
     }
  
  }


   componentDidMount(){ 

    let bsource = this.state.bookingsource; 
    const quvalues = queryString.parse(this.props.location.search);
    if(quvalues.bsource){       
      localStorage.setItem(BOOKING_SOURCE, quvalues.bsource); 
      this.setState({bookingsource: quvalues.bsource}); 
      this.getsmartbookingconfigdetails(quvalues.bsource);
      this.loadCurrentUser();
    }else{

      if (localStorage.getItem(BOOKING_SOURCE) !== null) {
        bsource = localStorage.getItem(BOOKING_SOURCE);      
        this.setState({bookingsource: bsource}); 
        this.getsmartbookingconfigdetails(bsource); 
        this.loadCurrentUser();     
      }else{
        let defttheme = this.state.bookingsource;
        this.getsmartbookingconfigdetails(defttheme);
        this.loadCurrentUser();
      }

      
    }

     
   }



  loadCurrentUser(){
    let locstatus = false;
    
    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }    

  }



  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true});                     
          }            
      }).catch(error => {
        
      });
  
   } 


  logintooauth(){ 
 
    let cdata = this.state.userlogin;

    var data ={
      email: cdata.username,
      password:cdata.password,
      role: "student",
     }  
  
     userlogin(data)
      .then(response => {  
 
          this.setState({showloading: false});  
          if(response.status == "success") {  
             
            localStorage.setItem(CA_ACCESS_TOKEN, response.token);              
            localStorage.setItem(sam_adminloggedstatus, 'logged');   
            localStorage.setItem(CA_LOOGED_USER_DATA, JSON.stringify(response.userdetails)); 
            this.setState({logmessage: ''});  
            this.props.history.push("/dashboard");
            
          }else{
            this.setState({logmessage: ''});    
            notification.error({
              message: 'Login Failed',
              description: 'Your Username or Password is incorrect. Please try again!',
              duration: 2,
              placement: 'topLeft'
            });   
  
         }
  
          
      }).catch(error => {
        this.setState({showloading: false});  
      });

  
   } 


  render() {
    
    let loggedflag = this.state.loggedstatus;
    if (loggedflag == "logged") {
        return <Redirect to='/dashboard' />
    }else{
      // window.location.href = 'https://www.tango-amistoso.co.uk/my-account/';
       //return null;
    }

    return(

      (this.state.loadingthemconfig == true) 

      ?

<Layout>
<Helmet><title>{this.state.smarbookingconfig.sitetitle} -  Portal</title></Helmet>    
   <Header className="login-header" style={{ background: '#222328', padding: 0 }}>
  <div><Row><Col> <div className="login-container "><img class="login_logo" src={this.state.smarbookingconfig.logopath}/></div>  </Col></Row>    
  </div>      
      </Header>
        <Layout>
      <Content style={{ margin: '24px 16px 0' }}>  
         <div className={this.state.smarbookingconfig.themeparentclass}>           
        <UserLogin smarbookingconfig ={this.state.smarbookingconfig} showloading={this.state.showloading} userdata={this.state.userlogin} fnhandleonchage={this.handleinputonchange} fnonloginsubmit={this.handleloginSubmit} />      
        </div> 
      </Content>      
    </Layout>
  </Layout>

:
<Spin tip="Loading..." />  
    );
  }
}

export default PageUserLogin;
