import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu, Icon,Breadcrumb,Avatar, Row, Col,Dropdown,Badge } from 'antd';
import 'antd/dist/antd.css';
import { MailOutlined  } from '@ant-design/icons';
import Menureport from '../images/menureport.png';
import Menuadminuser from '../images/menuadminuser.png';
import Menutest from '../images/menutest.png';
import Menuuser from '../images/menuuser.png';
import Menuadmin from '../images/adminuser.png';
import Menusubscribe from '../images/subscribe.png';
import Menusubscriber from '../images/subscriber.png';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Leftmenu extends  Component{
 
    constructor(props){
         super(props); 
    }


render(){

    return(              
        <Menu 
           theme="light"   
           mode="inline" className="sideright_menu"  
           defaultSelectedKeys={[ this.props.submenuid ]}           
           defaultOpenKeys={[this.props.menuid]}     
        >
          <Menu.Item key="1"><img className="menuicon" src={Menutest}/><a href="/dashboard"><span>Dashboard</span></a></Menu.Item> 
          <Menu.Item key="2"><img className="menuicon" src={Menutest}/><a href="/myclasses"><span>Attendance</span></a></Menu.Item>  
          <Menu.Item key="2"><img className="menuicon" src={Menutest}/><a href="/mysubscriptions"><span>My Subscriptions</span></a></Menu.Item>  
          <Menu.Item key="3"><img className="menuicon" src={Menutest}/><a href="/bookinghistory"><span>Booking History</span></a></Menu.Item>          
          <Menu.Item key="4"><img className="menuicon" src={Menutest}/><a href="/myprofile"><span>My Profile</span></a></Menu.Item>   
          <Menu.Item key="5"><img className="menuicon" src={Menutest}/><a href="/viewcredits"><span>Credits</span></a></Menu.Item>   
        </Menu>
    );
}
}
export default Leftmenu;