import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Checkoutbasket.css';
import "../../css/fonts.css";
import { Layout, Menu,Collapse,Spin,Modal } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompCheckoutbasket from '../../components/Checkoutbasket';
import CompCheckoutbasketlogged from '../../components/Checkoutbasketlogged';
import {validatetoken,getconfigurlmode, getcartdetails,userlogin,getstudentdetailsbyid,getpaymentintent,getsavedcardsstripe,useremailvalidate,removecartitem,getcountrylists,updatecartitemindividual,udpateitemquantity,wpauthlogin,usdetailsbyid,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Link } from 'react-router-dom';
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE } from '../../constants';
import { Helmet } from 'react-helmet';
import { Redirect } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';

const databreadcrumb = [
  {
    title: 'Checkout',
    linkurl: ''
  },
  
];

const queryString = require('query-string');

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

const stripe = require('stripe')('sk_test_pDVpSQ6uEQbXeneqkIhyO9ln00uZFo3OFp')

class Checkoutbasket extends Component {

  constructor(props){
    super(props);
    this.state = {
      flag:false,
      userlogin:{
          username: '',
          password: '',
      },
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:'',
        userid: 0,
      }, 
      userflag:1,     
      cartdata:{
        cartid: '',
        cartitems: [],
        userdetails: [],
        discountdata:[], 
        totalamount: 0,
        discountamount:0,
        grandtotalamount:0,
        cartcount: 0,
        attendeeflag: 0,    
        api_key: '',   
      },
      loadclassflag : false,
      userdetails:{
        salutation:'',
        firstname:'',
        lastname:'',
        email:'',
        phone_number:'',
        password:'',
        confirmpassword: '',
        address_line1:'',
        address_line2:'',
        city:'',
        state:'',
        post_code:'',
        country:'GB',
        user_category:'',
        newsletter_subscription: 1,
      },
      showpaymentform: false,
      stripepaymentintent: {
        clientSecret : '',
      },
      payactivetabs: ['1','2','3'],
      usersavedcards: [],
      paycardflag: 'newcard',
      loggeduserid: 0,
      activecardid :'',
      countrylist: [],
      addtionalattendees:[],
      cart_source: '',
      stripepromise: '',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta',   
      credits_pay: 0,
      total_credits: 0,
      showButton: false,
      showCredits: false,
      subscription_booking: false,
      terms_conditions: false
  }   
      this.handleonchangeuserradio = this.handleonchangeuserradio.bind(this);
      this.handleLogout = this.handleLogout.bind(this);  
      this.handlepaymentredirect = this.handlepaymentredirect.bind(this);
      this.handleinputonchange = this.handleinputonchange.bind(this);
      this.handleloginSubmit = this.handleloginSubmit.bind(this);
      this.handleinputchangenewuser = this.handleinputchangenewuser.bind(this);
      this.handlsubmitnewuser = this.handlsubmitnewuser.bind(this);
      this.handlepaymentmethodsavedcard = this.handlepaymentmethodsavedcard.bind(this);
      this.handleinputexistinuseronchange = this.handleinputexistinuseronchange.bind(this);
      this.handlsubmitexistinguser = this.handlsubmitexistinguser.bind(this);
      this.handlvalidateemailexists = this.handlvalidateemailexists.bind(this);
      this.handleremoveitems = this.handleremoveitems.bind(this);
      this.handlenewsleteonchange = this.handlenewsleteonchange.bind(this);
      this.handleonchangesubstdate = this.handleonchangesubstdate.bind(this);
      this.handlonchangeitemqty = this.handlonchangeitemqty.bind(this);
      this.handleonchangeattendee = this.handleonchangeattendee.bind(this);
      this.handleinputcreditsonchange = this.handleinputcreditsonchange.bind(this);
      this.handlsubmitcredits = this.handlsubmitcredits.bind(this);
      this.handletermsconditionsonchange = this.handletermsconditionsonchange.bind(this);
  }

  clearatttendeeerrormessages(){

    let csdata = this.state.cartdata;
    let citems = csdata.cartitems;
    let attflag = csdata.attendeeflag;

    if(attflag == 1){
      let ccount = citems.length;
      for(var i=0; i<ccount; i++){ 
        document.getElementById('error_attendee_' + i).innerHTML = "";
      }      
    }

  }

  validateattendeedata(){

    this.clearatttendeeerrormessages();

    let csdata = this.state.cartdata;
    let citems = csdata.cartitems;
    let attflag = csdata.attendeeflag;
    let cartattendees = [];
    let attvalidflag = 1;

    if(attflag == 1){
      let ccount = citems.length;

      for(var i=0; i<ccount; i++){
        let arrnewattends = [];
         let arriarrattendee = citems[i].attendeelist;
         let acount = arriarrattendee.length;
         for(var j=0; j<acount; j++){   
            if(arriarrattendee[j].firstname.trim() == ""){
                document.getElementById('error_attendee_' + i).innerHTML = "Please enter the attendee First Name";
                attvalidflag = 0;
                break;
            }else if(arriarrattendee[j].lastname.trim() == ""){
                document.getElementById('error_attendee_' + i).innerHTML = "Please enter the attendee Last Name";
                attvalidflag = 0;
                break;
            }else if(arriarrattendee[j].email.trim() == ""){
                document.getElementById('error_attendee_' + i).innerHTML = "Please enter the attendee Email";
                attvalidflag = 0;
                break;
            }else if(arriarrattendee[j].usercategory.trim() == ""){
                document.getElementById('error_attendee_' + i).innerHTML = "Please select the Entry Level";
                attvalidflag = 0;
                break;
            }else{  
              arrnewattends.push(arriarrattendee[j]);
            }
         }

         let arrpushitems = {
          cart_id: csdata.cartid,
          item_id: citems[i].item_id,
          attendees: arrnewattends,
         }
         cartattendees.push(arrpushitems);           
      }       
      
      if(attvalidflag == 1){        
        this.submituserregistration(cartattendees);
      }

      
    }else{
      this.submituserregistration(cartattendees);
    }
    
    
  }

  handlonchangeitemqty(event,itemdata){   
   let iquantity = event.target.value;
   this.updateitemquantity(iquantity,itemdata);
  }

  handleonchangeattendee(event,cindex,aindex){

    let csdata = this.state.cartdata;
    let citems = csdata.cartitems;
    let elid = event.target.id;

    if(elid == "afirstname"){        
      citems[cindex].attendeelist[aindex].firstname = event.target.value;            
    }

    if(elid == "alastname"){        
      citems[cindex].attendeelist[aindex].lastname = event.target.value;            
    }

    if(elid == "aemail"){        
      citems[cindex].attendeelist[aindex].email = event.target.value;            
    }

    if(elid == "ausercategory"){        
      citems[cindex].attendeelist[aindex].usercategory = event.target.value;            
    }
   
    csdata.cartitems = citems;
    this.setState({cartdata: csdata}); 

  }

  handleonchangesubstdate(date, dateString,itemid){
   
    let cartdatanew = this.state.cartdata;
    let cartitemms = cartdatanew.cartitems;
    let newcartitemudpate = cartitemms[itemid];
    cartitemms[itemid].substdate = dateString;
    cartdatanew.cartitems = cartitemms;
    this.setState({cartdata: cartdatanew}); 
    this.updatecartitemsnew(newcartitemudpate); 



  }


  updateitemquantity(iqty,itemdata){  

    let cartdata = this.state.cartdata;
    let cartid = cartdata.cartid;
    var data ={
      cart_id : cartdata.cartid, 
      item_id : itemdata.item_id,
      item_qty : iqty,              
    }  
    
  
    this.setState({loadclassflag: true}); 

    udpateitemquantity(data).then(response => {             
       if(response.status == "success") { 
        this.getcartitems(cartid);      
       }else{
         
      }  
      this.setState({loadclassflag: false});           
   }).catch(error => {
     
   });


}


  updatecartitemsnew(cartitems){  

    let cartdata = this.state.cartdata;
    var data ={
      cart_id : cartdata.cartid, 
      cart_item : cartitems,             
    }  
    
    this.setState({loadclassflag: true}); 

    updatecartitemindividual(data).then(response => {             
       if(response.status == "success") { 
            //window.location.reload();          
       }else{
         
      }  
      this.setState({loadclassflag: false});           
   }).catch(error => {
     
   });


}


  handlenewsleteonchange(event){
    let cdata = this.state.userdetails;
    if(event.target.checked == true){
      cdata.newsletter_subscription = 1;
    }else{
      cdata.newsletter_subscription = 0;
    }      
    this.setState({userdetails: cdata});    
   
}
handletermsconditionsonchange(event){     
  this.setState({terms_conditions: event.target.checked});   
}
  
  handleremoveitems(itemdata){  

        let cartdata = this.state.cartdata;
        var data ={
          cart_id : cartdata.cartid, 
          item_id : itemdata.item_id,             
        }     

       removecartitem(data).then(response => {             
           if(response.status == "success") { 
                window.location.reload();          
           }else{
             
          }            
       }).catch(error => {
         
       });


  }


  handlvalidateemailexists(){
    document.getElementById('error_usremail').innerHTML = '';
    let cdata = this.state.userdetails;      
    let useremail = cdata.email;

    var data ={
      email : useremail,            
     }  
       
     useremailvalidate(data)
      .then(response => {   
         
          if(response.status == "success") { 

            if(response.userstatus == 1) {
              let cdata = this.state.userdetails;  
              cdata.email = '';
              this.setState({userdetails:cdata}); 
              document.getElementById('usremail').value = '';               
              document.getElementById('usremail').focus();                   
              document.getElementById('error_usremail').innerHTML = response.message;
            }
              
          }else{
            
         } 
          
      }).catch(error => {
        
      });

  }


  handlsubmitexistinguser(){
     
    let cdata = this.state.userdetails;  
    let validateform = 1; 

        if(cdata.address_line1.trim() == "" ){  
          document.getElementById('usrbiladdress1').focus();                   
          document.getElementById('error_usrbiladdress1').innerHTML='Please enter the billing address';
          validateform = 0;
        } else if(cdata.city.trim() == "" ){  
          document.getElementById('usrcity').focus();                   
          document.getElementById('error_usrcity').innerHTML='Please enter the city';
          validateform = 0;
        } else if(cdata.post_code.trim() == "" ){  
          document.getElementById('usrpostcode').focus();                   
          document.getElementById('error_usrpostcode').innerHTML='Please enter the postcode';
          validateform = 0;
        }    
   
        if(validateform == 1){
          this.validateattendeedata();
          let udata  = this.state.loggeuserdata;
          this.loadsavedcards(udata.email);
        }


}
handlsubmitcredits(){
  this.setState({showCredits: true})
  let rtcartdata = this.state.cartdata;
  const  totalamount = this.state.cartdata.totalamount;
  const credits_pay = this.state.credits_pay;
  rtcartdata.grand_total = totalamount - credits_pay;
  this.setState({cartdata: rtcartdata});
}



  clearallerrormesssage3(){    
    document.getElementById('error_usrbiladdress1').innerHTML = '';
    document.getElementById('error_usrbiladdress2').innerHTML = '';
    document.getElementById('error_usrcity').innerHTML = '';
    document.getElementById('error_usrpostcode').innerHTML = '';
    document.getElementById('error_usrcountry').innerHTML = '';
    this.setState({logmessage: ''});    
}


  handleinputexistinuseronchange(event) {
      
    this.clearallerrormesssage3();

    let elid = event.target.id;
    let cdata = this.state.userdetails;    
    
    if(elid == "usrbiladdress1"){        
      cdata.address_line1 = event.target.value;            
    }

    if(elid == "usrbiladdress2"){        
      cdata.address_line2 = event.target.value;            
    }

    if(elid == "usrcity"){        
      cdata.city = event.target.value;            
    }

    if(elid == "usrpostcode"){        
      cdata.post_code = event.target.value;            
    }

    if(elid == "usrcountry"){        
      cdata.country = event.target.value;            
    }
          
    this.setState({userdetails: cdata});   
      
 }

 handleinputcreditsonchange(event){
  console.log(event.target.value);
    console.log(this.state.total_credits)
    this.setState({showButton: false})   
    if(parseInt(this.state.total_credits) >= parseInt(event.target.value))
    {
      this.setState({credits_pay: event.target.value})
      console.log('if')
    }
    else{
      this.setState({showButton: true})
      console.log('else')
    }
 }

  handlepaymentmethodsavedcard(event){
    this.setState({paycardflag: event.target.value });    
    this.setState({activecardid: event.target.value });
  }

 handlsubmitnewuser(){
     
      this.clearallerrormesssage2();
      let cdata = this.state.userdetails;  
      let validateform = 1; 
       if(cdata.salutation.trim()== ""){  
        document.getElementById('salutation').focus();                   
        document.getElementById('error_salutation').innerHTML='Please select the salutation';
        validateform = 0;
       } else if(cdata.firstname.trim()== ""){  
       document.getElementById('firstname').focus();                   
       document.getElementById('error_firstname').innerHTML='Please enter the firstname';
       validateform = 0;
      } else if(cdata.lastname.trim()== ""){  
          document.getElementById('lastname').focus();                   
          document.getElementById('error_lastname').innerHTML='Please enter your lastname';
          validateform = 0;
      } else if(cdata.email.trim()== ""){  
        document.getElementById('usremail').focus();                   
        document.getElementById('error_usremail').innerHTML='Please enter your email';
        validateform = 0;
      } else if(cdata.phone_number.trim() == ""){  
      document.getElementById('usrphoneno').focus();                   
      document.getElementById('error_usrphoneno').innerHTML='Please enter your email';
      validateform = 0;
      } else if(cdata.password.trim()== ""){  
      document.getElementById('usrpassword').focus();                   
      document.getElementById('error_usrpassword').innerHTML='Please enter your password';
      validateform = 0;
     } else if(cdata.password.trim() != cdata.confirmpassword.trim() ){  
      document.getElementById('usrcnfpassword').focus();                   
      document.getElementById('error_usrcnfpassword').innerHTML='confirm password should match with the password';
      validateform = 0;
     } else if(cdata.address_line1.trim() == "" ){  
      document.getElementById('usrbiladdress1').focus();                   
      document.getElementById('error_usrbiladdress1').innerHTML='Please enter the billing address';
      validateform = 0;
     } else if(cdata.city.trim() == "" ){  
      document.getElementById('usrcity').focus();                   
      document.getElementById('error_usrcity').innerHTML='Please enter the city';
      validateform = 0;
     } else if(cdata.post_code.trim() == "" ){  
      document.getElementById('usrpostcode').focus();                   
      document.getElementById('error_usrpostcode').innerHTML='Please enter the postcode';
      validateform = 0;
     } else if(cdata.user_category.trim() == "" ){  
      document.getElementById('user_category').focus();                   
      document.getElementById('error_user_category').innerHTML='Please select the entry level';
      validateform = 0;
     }     
        
             
     if(validateform == 1){
          //this.submituserregistration();
        this.validateattendeedata();
     }

     


 }


 submituserregistration(cartattendees){

      this.setState({loadclassflag: true});  
      let cdata = this.state.userdetails;
      let cartdata = this.state.cartdata;
      let luserid = this.state.loggeduserid;
      let credits_pay = this.state.credits_pay;

       var data ={
        cart_id : cartdata.cartid,
        user_id: luserid,
        user_data: cdata,
        payment_method :'stripe', 
        attendeedata: cartattendees,  
        credits_pay: credits_pay     
       }      
      
       
       getpaymentintent(data)
        .then(response => {  
       
            this.setState({showloading: false});  
            if(response.status == "success") { 
                let stpayintent = this.state.stripepaymentintent;    
                stpayintent.clientSecret =  response.clientsecret;       
                this.setState({stripepaymentintent: stpayintent});
                this.setState({showpaymentform: true});                
                let tabact = ['2','3'];
                this.setState({payactivetabs: tabact});  
            }
            else if(response.status == "payment_success") { 
              let cinpage = getconfigurlmode();
              const redirectpaymenturl = cinpage.paymentconfirmurl;
              window.location.href = redirectpaymenturl + cartdata.cartid + "&pay_credits=true";
            }
            else{
              
            }
           this.setState({loadclassflag: false}); 
    
            
        }).catch(error => {
          this.setState({showloading: false});  
        });
       

 }


  handleinputchangenewuser(event) {
      
    this.clearallerrormesssage2();

    let elid = event.target.id;
    let cdata = this.state.userdetails;    
    
    if(elid == "salutation"){        
      cdata.salutation = event.target.value;            
    }

    if(elid == "firstname"){        
      cdata.firstname = event.target.value;            
    }
    if(elid == "lastname"){        
      cdata.lastname = event.target.value;            
    }
    if(elid == "usremail"){        
      cdata.email = event.target.value;            
    }
    if(elid == "usrphoneno"){        
      cdata.phone_number = event.target.value;            
    }
    if(elid == "usrpassword"){        
      cdata.password = event.target.value;            
    }
    if(elid == "usrcnfpassword"){        
      cdata.confirmpassword = event.target.value;            
    }

    if(elid == "usrbiladdress1"){        
      cdata.address_line1 = event.target.value;            
    }

    if(elid == "usrbiladdress2"){        
      cdata.address_line2 = event.target.value;            
    }

    if(elid == "usrcity"){        
      cdata.city = event.target.value;            
    }

    if(elid == "usrpostcode"){        
      cdata.post_code = event.target.value;            
    }

    if(elid == "usrcountry"){        
      cdata.country = event.target.value;            
    }

    if(elid == "user_category"){        
      cdata.user_category = event.target.value;            
    }
          
    this.setState({userdetails: cdata});   
      
 }

 clearallerrormesssage2(){

    document.getElementById('error_salutation').innerHTML = '';
    document.getElementById('error_firstname').innerHTML = '';
    document.getElementById('error_lastname').innerHTML = ''; 
    document.getElementById('error_usremail').innerHTML = '';
    document.getElementById('error_usrphoneno').innerHTML = '';
    document.getElementById('error_usrpassword').innerHTML = '';
    document.getElementById('error_usrcnfpassword').innerHTML = '';
    document.getElementById('error_usrbiladdress1').innerHTML = '';
    document.getElementById('error_usrbiladdress2').innerHTML = '';
    document.getElementById('error_usrcity').innerHTML = '';
    document.getElementById('error_usrpostcode').innerHTML = '';
    document.getElementById('error_usrcountry').innerHTML = '';
    document.getElementById('error_user_category').innerHTML = '';
    this.setState({logmessage: ''});    
}


  clearallerrormesssage(){
    document.getElementById('error_email').innerHTML = '';
    document.getElementById('error_password').innerHTML = ''; 
    document.getElementById('error_login').innerHTML = ''; 
    this.setState({logmessage: ''});    
  }

   handleinputonchange(event) {
      
     let elid = event.target.id;
     let cdata = this.state.userlogin;    
     if(elid == "loggemail"){  
       document.getElementById('error_email').innerHTML = ''; 
       cdata.username = event.target.value;            
     }
     if(elid == "loggpassword"){  
       document.getElementById('error_password').innerHTML = '';  
       cdata.password = event.target.value;            
     }       
     this.setState({userlogin: cdata});   
    
  }

  handleloginSubmit() {
 
    this.clearallerrormesssage(); 
    let cdata = this.state.userlogin;
     let validateform = 1; 
     if(cdata.username.trim()== ""){  
      document.getElementById('loggemail').focus();                   
      document.getElementById('error_email').innerHTML='Please enter the username';
      validateform = 0;
     }
     else if(cdata.password.trim()== ""){  
         document.getElementById('loggpassword').focus();                   
         document.getElementById('error_password').innerHTML='Please enter your password';
         validateform = 0;
     }
  
     if(validateform == 1){
       this.setState({showloading: true});  
       this.logintooauth();
     }
  
  }


  logintooauth(){ 
    
    document.getElementById('error_login').innerHTML = 'Logging. Please wait....';

    let cdata = this.state.userlogin;

    var data ={
      email: cdata.username,
      password:cdata.password,
      role: "student",
     }  
  
     userlogin(data)
      .then(response => {  
 
          this.setState({showloading: false});  
          if(response.status == "success") {  
            document.getElementById('error_login').innerHTML = ''; 
            localStorage.setItem(CA_ACCESS_TOKEN, response.token);              
            localStorage.setItem(sam_adminloggedstatus, 'logged');   
            localStorage.setItem(CA_LOOGED_USER_DATA, JSON.stringify(response.userdetails)); 
            this.setState({logmessage: ''});  
            window.location.reload();
            
          }else{
            this.setState({logmessage: ''});
            document.getElementById('error_login').innerHTML = 'Your Username or Password is incorrect. Please try again!';    
         }
  
          
      }).catch(error => {
        this.setState({showloading: false});  
      });

  
   } 

  

  async handlepaymentredirect(){
        console.log(1);

        const session = await stripe.checkout.sessions.create({
          line_items: [
            {
              price_data: {
                currency: 'usd',
                product_data: {
                  name: 'T-shirt',
                },
                unit_amount: 2000,
              },
              quantity: 1,
            },
          ],
          mode: 'payment',
          success_url: 'https://example.com/success',
          cancel_url: 'https://example.com/cancel',
        });
      
        console.log( session)

  }



  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA); 
    localStorage.removeItem(TA_CART_ID);      
    this.props.history.push('/');     
}

  handleonchangeuserradio(event){
    this.setState({userflag: event.target.value });
  }

   componentDidMount(){ 
    this.loadCurrentUser();
    this.getcountrylists();
    const quvalues = queryString.parse(this.props.location.search);
    
    if(quvalues.wplogged ){ 
    
      if(quvalues.cartid ){     
        let checkcartid = quvalues.cartid;  
        let wplogged = quvalues.wplogged;
        localStorage.setItem(TA_CART_ID, checkcartid);      
        this.authenticatewplogin(checkcartid,wplogged);        
      } 
    
    }else{
      
      if(quvalues.cartid ){     
        let checkcartid = quvalues.cartid;  
        localStorage.setItem(TA_CART_ID, checkcartid);      
        this.getcartitems(checkcartid);        
      } 

    }    

   }

   authenticatewplogin(checkcartid,wplogged){

    const data={
      validate: wplogged,      
    }     
    validatetoken(data).then(response => {
          if(response.status == 'success'){  
            localStorage.setItem(CA_ACCESS_TOKEN, response.token);              
            localStorage.setItem(sam_adminloggedstatus, 'logged');   
            localStorage.setItem(CA_LOOGED_USER_DATA, JSON.stringify(response.userdetails)); 
            this.loadCurrentUser();
            this.getcartitems(checkcartid);   
          }       
      })
    // this.setState({loadclassflag: true}); 
    // const data={
    //   userid: 0,      
    // } 
    // wpauthlogin(data).then(response => {
    //   if(response.status == 'success'){             
    //        let logemail = response.loggedemail;      
    //        this.getuserdetailsbyemail(logemail,checkcartid);
    //     }else{         
    //       localStorage.setItem(TA_CART_ID, checkcartid);      
    //       this.getcartitems(checkcartid);  
    //     }       
    // })


   }


   getuserdetailsbyemail(uemail,cardid){
     
    const data={
      email: uemail,      
    }     
    usdetailsbyid(data).then(response => {
          if(response.status == 'success'){  
            localStorage.setItem(CA_ACCESS_TOKEN, response.token);              
            localStorage.setItem(sam_adminloggedstatus, 'logged');   
            localStorage.setItem(CA_LOOGED_USER_DATA, JSON.stringify(response.userdetails)); 
            this.loadCurrentUser();
            this.getcartitems(cardid);   
          }       
      })
  }


   getcountrylists(){

    let searchprops = this.state.filterparams;   
    const data={
      userid: 0,      
    }     
    getcountrylists(data).then(response => {
          if(response.status == 'success'){  
                this.setState({countrylist: response.countrylists});
          }       
      })
  }



   getcartitems(checkcartid){

    this.setState({loadclassflag: true}); 
    let udata  = this.state.loggeuserdata;
    const data={
      cart_id: checkcartid, 
      user_id: udata.userid,    
    }     
    getcartdetails(data).then(response => {
          if(response.status == 'success'){          
            
           // let resdata = response.cartlist[0];    
            let rtcartdata = this.state.cartdata;
            rtcartdata.api_key = response.api_key;
            rtcartdata.cartid = response.cart_id;
            rtcartdata.cartitems = response.cart_items;
            rtcartdata.totalamount = response.total_price;
            rtcartdata.discountamount = response.discount_value;
            rtcartdata.grand_total = response.grand_total;
            rtcartdata.cartcount =response.cart_count;
            rtcartdata.attendeeflag =response.attendeeflag;
            this.setState({cartdata: rtcartdata});

            let cartsource  = response.cart_source;
            if(cartsource == "smtf"){
              document.documentElement.style.setProperty('--primarycolor', "#890009"); 
              let cinpage = getconfigurlmode(); 
              const stpnew = loadStripe(cinpage.tanamsstpkey);
              this.setState({stripepromise: stpnew});
            }else
            {
              document.documentElement.style.setProperty('--primarycolor', "#c33131");
              let cinpage = getconfigurlmode();  
              const stpnew = loadStripe(cinpage.tanamsstpkey); 
              this.setState({stripepromise: stpnew});
            }

            //  

            this.setState({cart_source: response.cart_source});

            localStorage.setItem(BOOKING_SOURCE, response.cart_source); 

            let uddata = response.user_data;
            if(uddata){

              if(uddata.length > 0) {
                      let userdatafromapi = uddata[0];
                      let udata = this.state.userdetails;  
                      udata.firstname = userdatafromapi.firstname;
                      udata.lastname = userdatafromapi.lastname;
                      udata.email = userdatafromapi.email;
                      udata.phone_number = userdatafromapi.phone_number;       
                      udata.address_line1 = userdatafromapi.address_line1;
                      udata.address_line2 = userdatafromapi.address_line2;
                      udata.city = userdatafromapi.city;
                      udata.state = userdatafromapi.state;
                      udata.post_code = userdatafromapi.post_code; 
                      udata.salutation = userdatafromapi.salutation; 
                      udata.user_category = userdatafromapi.user_category; 
                      this.setState({userdetails: udata});   
              }


              this.setState({bookingsource: response.cart_source}); 
              this.getsmartbookingconfigdetails(response.cart_source);    
              
              /*
              udata.firstname = userdatafromapi.firstname;
              udata.lastname = userdatafromapi.lastname;
              udata.email = userdatafromapi.email;
              udata.phone_number = userdatafromapi.phone_number;       
              udata.address_line1 = userdatafromapi.address_line1;
              udata.address_line2 = userdatafromapi.address_line2;
              udata.city = userdatafromapi.city;
              udata.state = userdatafromapi.state;
              udata.post_code = userdatafromapi.post_code;   
              this.setState({userdetails: udata});   
              */ 
             
              if(response.invaliditemflag == 1){
                this.showinvaliditemsinfo(response.invaliderrmessage);
              }
            }
           
 
          }
          this.setState({loadclassflag: false}); 
          this.setState({total_credits: response.credits}); 
          this.setState({subscription_booking: response.subscription_booking}); 
          if(response.credits <= 0) {
            this.setState({subscription_booking: true}); 
          }
      })
}


getusedetailsbyid(userid){
  
      const postdata={
        userid: userid,       
      }     
      getstudentdetailsbyid(postdata).then(response => {
            if(response.status == 'success'){   
              let udata  = this.state.userdetails;
              let userdatafromapi = response.userdetails;
              udata.firstname = userdatafromapi.firstname;
              udata.lastname = userdatafromapi.lastname;
              udata.email = userdatafromapi.email;
              udata.phone_number = userdatafromapi.phone_number;       
              udata.address_line1 = userdatafromapi.address_line1;
              udata.address_line2 = userdatafromapi.address_line2;
              udata.city = userdatafromapi.city;
              udata.state = userdatafromapi.state;
              udata.post_code = userdatafromapi.post_code;       
              this.setState({userdetails: udata});
            }            
        })
}


   

   loadCurrentUser(){

    let locstatus = false;
    let bsource = this.state.bookingsource;

    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata}); 
      this.setState({loggeduserid: udata.userid});     
      this.getusedetailsbyid(udata.userid);
      this.loadsavedcards(udata.email);
    }

    
    

  }

  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true});            
           
          }            
      }).catch(error => {
        
      });
  
   }


  loadsavedcards(uemail){ 
    
    let bsource = this.state.bookingsource; 

    const postdata={
      email: uemail,  
      stripepartner: bsource,     
    }     
    getsavedcardsstripe(postdata).then(response => {
          if(response.status == 'success'){             
           this.setState({usersavedcards: response.cartdata});
          }            
      })
  }

  showinvaliditemsinfo(strMessage) {
    Modal.info({
      title: 'Checkout Notification',
      content: (
        <div>
          <p>{strMessage}</p> 
          <p>Please <a href="https://www.tango-amistoso.co.uk/tango-amistoso-subscriptions/">click here</a> to see all subscription.</p>      
        </div>
      ),
      onOk() {},
    });
  }



  render() {
    
    let loggedflag = this.state.loggedstatus;
    if (loggedflag == "logged") {
       // return <Redirect to='/dashboard' />
    }

    const text = 'adsfasdfasdf';
    return(

<div>
<Spin tip="Loading..."  spinning={this.state.loadclassflag} >
      {

        (loggedflag == "logged")  ?
        <div >
           
    <Layout>   
      <Helmet>
        <title>{this.state.smarbookingconfig.sitetitle} - Checkout</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-975571673"></script>
        <script src="https://www.tango-amistoso.co.uk/anayticjs/gaanaltyic.js" type="text/javascript" async=""></script>
        </Helmet>       
   <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartdata.cartid} />
   <Layout>
   <Sider breakpoint="lg"  collapsedWidth="0">
   <Leftmenu />
   </Sider>
   <Layout>    
     <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
     <PageBreadCrumb 
     databreadcrumb = {databreadcrumb}
     
     />
            
            <CompCheckoutbasketlogged 
              userflag = {this.state.userflag}
              handleonchangeuserradio = {this.handleonchangeuserradio}  
              cartdata = {this.state.cartdata} 
              loadclassflag ={this.state.loadclassflag} 
              userdetails = {this.state.userdetails}  
              stripepaymentintent = {this.state.stripepaymentintent}
              usersavedcards = {this.state.usersavedcards}
              paycardflag = {this.state.paycardflag}
              handlepaymentmethodsavedcard = {this.handlepaymentmethodsavedcard}
              showpaymentform = {this.state.showpaymentform} 
              handleinputexistinuseronchange = {this.handleinputexistinuseronchange}
              handlsubmitexistinguser = {this.handlsubmitexistinguser}
              activecardid = {this.state.activecardid} 
              handleremoveitems = {this.handleremoveitems}
              countrylist = {this.state.countrylist}
              handlenewsleteonchange = {this.handlenewsleteonchange}
              handleonchangesubstdate = {this.handleonchangesubstdate}
              handlonchangeitemqty = {this.handlonchangeitemqty}
              handleonchangeattendee = {this.handleonchangeattendee} 
              stripepromise = {this.state.stripepromise}
              handlsubmitcredits = {this.handlsubmitcredits}
              handleinputcreditsonchange = {this.handleinputcreditsonchange}
              credits_pay = {this.state.credits_pay}
              total_credits= {this.state.total_credits}
              showButton= {this.state.showButton}
              showCredits= {this.state.showCredits}
              subscription_booking= {this.state.subscription_booking}
              bookingsource= {this.state.cart_source}
              handletermsconditionsonchange = {this.handletermsconditionsonchange}
              terms_conditions = {this.state.terms_conditions}
            /> 

     </Content>
    <CommonFooter />
   </Layout>
 </Layout>
 </Layout>


          </div> 
        
        :
        <div className="commonbodylayout">
<Layout>   
      <Helmet>
        <title>{this.state.smarbookingconfig.sitetitle} - Checkout page</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-975571673"></script>
        <script src="https://www.tango-amistoso.co.uk/anayticjs/gaanaltyic.js?rid=10" type="text/javascript" async=""></script>
      </Helmet>       
   <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} cart_source={this.state.cart_source} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartdata.cartid} />
   <Layout>
   <PageBreadCrumb 
     databreadcrumb = {databreadcrumb}
     
     />
   <Layout>    
     <Content className='dasboard-page'>
     <CompCheckoutbasket 
        cart_source={this.state.cart_source}
        userflag = {this.state.userflag}
        handleonchangeuserradio = {this.handleonchangeuserradio}   
        handlepaymentredirect = {this.handlepaymentredirect} 
        cartdata = {this.state.cartdata} 
        loadclassflag ={this.state.loadclassflag}
        handleinputonchange = {this.handleinputonchange}
        handleloginSubmit = {this.handleloginSubmit}
        userdata={this.state.userlogin} 
        showpaymentform = {this.state.showpaymentform} 
        handleinputchangenewuser = {this.handleinputchangenewuser} 
        handlsubmitnewuser ={this.handlsubmitnewuser} 
        stripepaymentintent = {this.state.stripepaymentintent}
        userdetails = {this.state.userdetails}  
        payactivetabs = {this.state.payactivetabs}
        handlvalidateemailexists = {this.handlvalidateemailexists}
        handleremoveitems = {this.handleremoveitems}
        countrylist = {this.state.countrylist}
        handlenewsleteonchange = {this.handlenewsleteonchange}
        handleonchangesubstdate = {this.handleonchangesubstdate}
        handlonchangeitemqty = {this.handlonchangeitemqty}
        handleonchangeattendee = {this.handleonchangeattendee}   
        stripepromise = {this.state.stripepromise}    
        handletermsconditionsonchange = {this.handletermsconditionsonchange}
        terms_conditions = {this.state.terms_conditions}
        bookingsource = {this.state.cart_source}
     /> 
     </Content>
    <CommonFooter />
   </Layout>
 </Layout>
 </Layout>

 
 </div>
        
        }
</Spin>
</div>



    );
  }
}

export default Checkoutbasket;
