import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Myprofile.css';
import "../../css/fonts.css";
import { Layout, Menu, message,Spin } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompMyprofile from '../../components/Myprofile';
import { searchclassesbyfilter,getstudentdetailsbyid,getcountrylists,updatestudentprofile,resetpassword,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Redirect } from "react-router-dom";
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE} from '../../constants';
import { Helmet } from 'react-helmet';

const {  Content,  Sider } = Layout;

const databreadcrumb = [
  {
    title: 'Dashboard',
    linkurl: ''
  },
  
];

function getCurrentDate(separator='',format=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if(format == 1){
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }else{
   
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  }
  
  }

class Myprofile extends Component {

  constructor(props){
    super(props); 


    
    var clstdate = getCurrentDate('-',1);
    var clenddate = getCurrentDate('-',1);
    this.state = {
      collapsed: false,
      loggedstatus:'logged',
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      filterparams:{
        clastdate: clstdate,
        clsenddate: clenddate,
        classstatus: 1,
      },
      classlists: [],
      loadclassflag: false,
      cartid:'',
      userdetails:{
        userid:'',
        salutation:'',
        firstname:'',
        lastname:'',
        email:'',
        phone_number:'',      
        address_line1:'',
        address_line2:'',
        city:'',
        state:'',
        post_code:'',
        country:'',
        user_category:'',
        newsletter_subscription: 1,
      },
      countrylist: [],
      resetpwddata:{
        userid:'',
        useremail:'',
        password:'',
        cpassword:''
      },
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta', 
    };

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);  
    this.handleinputchangenewuser = this.handleinputchangenewuser.bind(this);
    this.handlsubmitnewuser = this.handlsubmitnewuser.bind(this); 
    this.handlenewsleteonchange = this.handlenewsleteonchange.bind(this); 
    this.handlepassordinpurtonchange = this.handlepassordinpurtonchange.bind(this);
    this.hanlesubmitresetpassword = this.hanlesubmitresetpassword.bind(this);
  }  

  handlepassordinpurtonchange(event){

        this.clearallerrormespasswrod();
        let elid = event.target.id;
        let cdata = this.state.resetpwddata;  
        if(elid == "newpassword"){        
          cdata.password = event.target.value;            
        }
        if(elid == "nconfirmpassword"){        
          cdata.cpassword = event.target.value;            
        }       
        this.setState({resetpwddata: cdata}); 
  }

  handlenewsleteonchange(event){
      let cdata = this.state.userdetails;
      if(event.target.checked == true){
        cdata.newsletter_subscription = 1;
      }else{
        cdata.newsletter_subscription = 0;
      }      
      this.setState({userdetails: cdata});   
     
  }


  clearallerrormespasswrod(){
    document.getElementById('error_newpassword').innerHTML = '';
    document.getElementById('error_nconfirmpassword').innerHTML = '';    
  }


  hanlesubmitresetpassword(){
    
    this.clearallerrormespasswrod();
    let cdata = this.state.resetpwddata;
    let  validateform = 1;

    if(cdata.password.trim()== ""){  
      document.getElementById('newpassword').focus();                   
      document.getElementById('error_newpassword').innerHTML='Please enter the new password';
      validateform = 0;
    } else if(cdata.password.trim() != cdata.cpassword.trim()){  
      document.getElementById('nconfirmpassword').focus();                   
      document.getElementById('error_nconfirmpassword').innerHTML='Should match with the new password';
      validateform = 0;
    }

    if(validateform == 1){
        this.updateuserpassword();
     }

  }


  updateuserpassword(){

    this.setState({loadclassflag: true}); 
     
    let cdata = this.state.resetpwddata; 
    const data={
      userid: cdata.userid,
      email: cdata.useremail,
      password: cdata.password,         
    }    
     
    resetpassword(data).then(response => {
          if(response.status == 'success'){ 
              message.success('Password updated successfully');
          }
          this.setState({loadclassflag: false}); 
      })
      
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };


  clearallerrormesssage2(){
    document.getElementById('error_salutation').innerHTML = '';
    document.getElementById('error_firstname').innerHTML = '';
    document.getElementById('error_lastname').innerHTML = ''; 
    document.getElementById('error_usremail').innerHTML = '';
    document.getElementById('error_usrphoneno').innerHTML = '';  
    document.getElementById('error_usrbiladdress1').innerHTML = '';
    document.getElementById('error_usrbiladdress2').innerHTML = '';
    document.getElementById('error_usrcity').innerHTML = '';
    document.getElementById('error_usrpostcode').innerHTML = '';
    document.getElementById('error_usrcountry').innerHTML = '';
    document.getElementById('error_user_category').innerHTML = '';
    this.setState({logmessage: ''});    
}


handlsubmitnewuser(){
          
        let cdata = this.state.userdetails;  
        let validateform = 1; 

        if(cdata.salutation.trim()== ""){  
          document.getElementById('salutation').focus();                   
          document.getElementById('error_salutation').innerHTML='Please select the salutation';
          validateform = 0;
        } else if(cdata.firstname.trim()== ""){  
        document.getElementById('firstname').focus();                   
        document.getElementById('error_firstname').innerHTML='Please enter the firstname';
        validateform = 0;
        } else if(cdata.lastname.trim()== ""){  
            document.getElementById('lastname').focus();                   
            document.getElementById('error_lastname').innerHTML='Please enter your lastname';
            validateform = 0;
        } else if(cdata.email.trim()== ""){  
          document.getElementById('usremail').focus();                   
          document.getElementById('error_usremail').innerHTML='Please enter your email';
          validateform = 0;
        } else if(cdata.phone_number.trim() == ""){  
        document.getElementById('usrphoneno').focus();                   
        document.getElementById('error_usrphoneno').innerHTML='Please enter your email';
        validateform = 0;
        } else if(cdata.address_line1.trim() == "" ){  
        document.getElementById('usrbiladdress1').focus();                   
        document.getElementById('error_usrbiladdress1').innerHTML='Please enter the billing address';
        validateform = 0;
      } else if(cdata.city.trim() == "" ){  
        document.getElementById('usrcity').focus();                   
        document.getElementById('error_usrcity').innerHTML='Please enter the city';
        validateform = 0;
      } else if(cdata.post_code.trim() == "" ){  
        document.getElementById('usrpostcode').focus();                   
        document.getElementById('error_usrpostcode').innerHTML='Please enter the postcode';
        validateform = 0;
      }
      else if(cdata.user_category.trim() == "" ){  
        document.getElementById('user_category').focus();                   
        document.getElementById('error_user_category').innerHTML='Please select the entry level';
        validateform = 0;
      }        
      
      if(validateform == 1){
          this.updatestudentprofiledata();
      }


}



updatestudentprofiledata(){

  this.setState({loadclassflag: true}); 

  let cdata = this.state.userdetails; 
  const data={
    user_id: cdata.userid,
    salutation: cdata.salutation,
    firstname: cdata.firstname,
    lastname: cdata.lastname,
    email: cdata.email,
    phone_number: cdata.phone_number,
    address_line1: cdata.address_line1,
    address_line2: cdata.address_line2,
    city: cdata.city,
    state: cdata.state,
    post_code: cdata.post_code,
    user_category: cdata.user_category,  
    user_country: cdata.country,
    newsletter_subscription: cdata.newsletter_subscription,      
  }    
   
  updatestudentprofile(data).then(response => {
        if(response.status == 'success'){ 
            message.success('Profile updated successfully');
        }
        this.setState({loadclassflag: false}); 
    })
    
}


  handleinputchangenewuser(event) {
      
    this.clearallerrormesssage2();

    let elid = event.target.id;
    let cdata = this.state.userdetails;  
    
    if(elid == "salutation"){        
      cdata.salutation = event.target.value;            
    }

    if(elid == "firstname"){        
      cdata.firstname = event.target.value;            
    }
    if(elid == "lastname"){        
      cdata.lastname = event.target.value;            
    }
    if(elid == "usremail"){        
      cdata.email = event.target.value;            
    }
    if(elid == "usrphoneno"){        
      cdata.phone_number = event.target.value;            
    }   

    if(elid == "usrbiladdress1"){        
      cdata.address_line1 = event.target.value;            
    }

    if(elid == "usrbiladdress2"){        
      cdata.address_line2 = event.target.value;            
    }

    if(elid == "usrcity"){        
      cdata.city = event.target.value;            
    }

    if(elid == "usrpostcode"){        
      cdata.post_code = event.target.value;            
    }

    if(elid == "usrcountry"){        
      cdata.country = event.target.value;            
    }

    if(elid == "user_category"){        
      cdata.user_category = event.target.value;            
    }
          
    this.setState({userdetails: cdata});   
      
 }

  
  componentDidMount(){ 
    this.loadCurrentUser(); 
    this.getcountrylists();     
  }


getuserdetailsbyid(uid){

  this.setState({loadclassflag: true}); 

  let searchprops = this.state.filterparams;   
  const data={
    userid: uid,      
  }     
  getstudentdetailsbyid(data).then(response => {
        if(response.status == 'success'){ 
          
              let udata  = this.state.userdetails;
              let userdatafromapi = response.userdetails;
              udata.userid = userdatafromapi.user_id;
              udata.salutation = userdatafromapi.salutation;
              udata.firstname = userdatafromapi.firstname;
              udata.lastname = userdatafromapi.lastname;
              udata.email = userdatafromapi.email;
              udata.phone_number = userdatafromapi.phone_number;       
              udata.address_line1 = userdatafromapi.address_line1;
              udata.address_line2 = userdatafromapi.address_line2;
              udata.city = userdatafromapi.city;
              udata.state = userdatafromapi.state;
              udata.post_code = userdatafromapi.post_code;  
              udata.user_category = userdatafromapi.user_category;  
              udata.user_country = userdatafromapi.country;  
              udata.newsletter_subscription = userdatafromapi.newsletter_subscription;  
              this.setState({userdetails: udata});

              let crdata = this.state.resetpwddata;
              crdata.userid =  userdatafromapi.user_id;
              crdata.useremail =  userdatafromapi.email;
              this.setState({resetpwddata: crdata});              
              
         
        }
        this.setState({loadclassflag: false}); 
    })
}


      getcountrylists(){

        let searchprops = this.state.filterparams;   
        const data={
          userid: 0,      
        }     
        getcountrylists(data).then(response => {
              if(response.status == 'success'){  
                    this.setState({countrylist: response.countrylists});
              }       
          })
      }



  loadCurrentUser(){

    let locstatus = false;
    let bsource = this.state.bookingsource; 

    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata}); 
      this.getuserdetailsbyid(udata.userid);
    }

    if (localStorage.getItem(TA_CART_ID) !== null) {
      this.setState({cartid: localStorage.getItem(TA_CART_ID)});
    }

    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);      
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }
    

  }

  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true});                      
          }            
      }).catch(error => {
        
      });
  
   }

  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA);  
    localStorage.removeItem(TA_CART_ID);      
    this.props.history.push('/');     
}


  render() { 
   
    let loggedflag = this.state.loggedstatus;
    if (loggedflag != 'logged') {
        return <Redirect to='/' />
    }

    return(

      (this.state.loadingthemconfig == true) 
      
      ?

            <Layout>   
            <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title></Helmet>       
            <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} cartid={this.state.cartid} />
            <Layout>
            <Sider breakpoint="lg"  collapsedWidth="0">
            <Leftmenu />
            </Sider>
            <Layout>    
              <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
              <PageBreadCrumb 
              databreadcrumb = {databreadcrumb}
              
              />
                      
              <CompMyprofile 
                  classlists = {this.state.classlists}
                  loadclassflag = {this.state.loadclassflag}
                  userdetails = {this.state.userdetails}
                  handleinputchangenewuser = {this.handleinputchangenewuser}
                  handlsubmitnewuser ={this.handlsubmitnewuser}
                  countrylist = {this.state.countrylist}
                  handlenewsleteonchange = {this.handlenewsleteonchange}
                  handlepassordinpurtonchange = {this.handlepassordinpurtonchange}
                  hanlesubmitresetpassword = {this.hanlesubmitresetpassword}
              />

              </Content>
              <CommonFooter />
            </Layout>
          </Layout>
          </Layout>

:
<Spin tip="Loading..." />
  
    );
  }
}

export default Myprofile;
