import React from 'react';
import './App.css';
import {BrowserRouter, Route,Switch,HashRouter,StaticRouter} from 'react-router-dom';
import LoginPage from '../pages/login/PageUserLogin';
import DashBoard from '../pages/dashboard/Dashboard';
import config from 'react-global-configuration';
import configuration from '../config/config';
import Checkoutbasket from '../pages/Checkoutbasket/Checkoutbasket';
import Checkoutconfirm from '../pages/Checkoutconfirm/Checkoutconfirm';
import AddNewTest from '../pages/addnewtest/Addnewtest';

import Classes from '../pages/Classes/Classes';
import Subscriptions from '../pages/Subscriptions/Subscriptions';
import Viewsubscription from '../pages/Viewsubscription/Viewsubscription';
import Viewcredits from '../pages/Viewcredits/Viewcredits';
import Bookinghistory from '../pages/Bookinghistory/Bookinghistory';

import Myprofile from '../pages/Myprofile/Myprofile';

import Checkoutstripe from '../pages/Checkoutstripe/Checkoutstripe';

import Cancelsubscription from '../pages/Cancelsubscription/Cancelsubscription';

import PageUserReset from '../pages/resetpassword/PageUserReset';

import LoginSubmit from '../pages/loginsubmit/loginsubmit';

config.set(configuration);

function App() {
  return (

    <BrowserRouter  basename='/' >
    
    <div>
      <Switch>  
     <Route exact={true} path='/' component={LoginPage} />      
     <Route exact={true} path='/dashboard' component={DashBoard} />      
     <Route exact={true} path='/checkoutbasket' component={Checkoutbasket }/>
     <Route exact={true} path='/bookingconfirm' component={Checkoutconfirm }/>
     <Route exact={true} path='/myclasses' component={Classes }/>
     <Route exact={true} path='/mysubscriptions' component={Subscriptions }/>
     <Route exact={true} path='/viewsubscription' component={Viewsubscription }/>
     <Route exact={true} path='/viewcredits' component={Viewcredits }/>

     <Route exact={true} path='/bookinghistory' component={Bookinghistory }/>
     <Route exact={true} path='/myprofile' component={Myprofile }/>

     <Route exact={true} path='/checkoutstripe' component={Checkoutstripe }/>

     <Route exact={true} path='/cancelsubscription' component={Cancelsubscription }/>
     <Route exact={true} path='/resetpassword' component={PageUserReset }/>
     
     <Route exact={true} path='/loginsubmit' component={LoginSubmit }/>
     </Switch>
    </div>
  </BrowserRouter>

    
  );
}

export default App;
