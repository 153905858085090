import React, { Component } from "react";
import '../pages/login/PageUserLogin.css';
import "../css/fonts.css";
import { img } from  '../images/logo-mobile.png';
import { Spin } from 'antd';
import { Link } from 'react-router-dom'

class Resetpassword extends  Component{

    constructor(props){
         super(props); 
    }



render(){

    return(
        <div class="login-page">
            <div class="conatiner">
            <div class="login-box">
        <div class="login-left">
        <div class="login-container">
		
        <div class="logo-login"><img src={this.props.smarbookingconfig.logopath}  /></div>

          <h3 class="login-heading">RESET PASSWORD?</h3>
		  
          {

            (this.props.otpsendflag == false) 
            ?
            <div>
                 <div class="row">
                <div class="row-label">
                <label>Email Address *</label>
                </div>
                <div class="row-label">
                <input type="text" class="input-border" name="email" id="loggemail" className="login-textbox" value={this.props.userdata.username} onChange={this.props.fnhandleonchage}  />
                <div class="erromessage" id="error_email"></div>
                </div>
                </div>          

                <div class="row">
                <div class="login-mt">
                
                {(this.props.showloading == true)?
                        <div>
                            <Spin tip="Please wait... Logging..." />
                        </div>
                        :
                        ''
                        } 
                <div class="login-btn">
                <button class="login-btn-style" onClick={this.props.fnonloginsubmit} >Submit</button>        
                </div>
                </div>
                </div>
          </div>

            :                               
                
               (this.props.showcontinue == true)?
                <div>
                    <div class="erromessage" id="error_resetconfirm"></div>
                    <div class="login-btn">
                    <button class="login-btn-style" onClick={this.props.handleredirectologinpage} >Login</button>        
                  </div>
                </div>
                :
                <div>
                <p>We have sent you the Reset Password Code to your email to reset your password. Please check your email</p>
                <div class="row">
                    <div class="row-label">
                    <label>Enter the Reset Password Code</label>
                    </div>
                    <div class="row-label">
                    <input type="text" class="input-border" name="emailotp" id="emailotp" className="login-textbox" value={this.props.resetpwddata.emailotp} onChange={this.props.handlepassworrest} />
                    <div class="erromessage" id="error_emailotp"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="row-label">
                    <label>Enter New Password</label>
                    </div>
                    <div class="row-label">
                    <input type="password" class="input-border" name="epassword" id="epassword" className="login-textbox" value={this.props.resetpwddata.epassword} onChange={this.props.handlepassworrest}/>
                    <div class="erromessage" id="error_epassword"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="row-label">
                    <label>Confirm Password</label>
                    </div>
                    <div class="row-label">
                    <input type="password" class="input-border" name="confirmpassword" id="confirmpassword" className="login-textbox" value={this.props.resetpwddata.confirmpassword}  onChange={this.props.handlepassworrest}/>
                    <div class="erromessage" id="error_confirmpassword"></div>
                    </div>
                </div>  
                {(this.props.showloading == true)?
                    <div>
                        <Spin tip="Please wait... Logging..." />
                    </div>
                    :
                    ''
                    }
                    <div class="erromessage" id="error_reset"></div>
                 <div class="login-btn">
                    <button class="login-btn-style" onClick={this.props.fnhandlepassworrest} >Reset</button>        
                  </div>  

            </div>   

          }

        </div>
        </div>
		<div class="login-right">
		<div class="login-content">
        <h3>{this.props.smarbookingconfig.sitetitle}</h3>
        <p>{this.props.smarbookingconfig.short_desc}</p>
		</div></div>
</div>
        </div>
        </div>
    );
}
}
export default Resetpassword;